<template>
  <div class="container main-container">
    <div class="second-container" v-if="isDesktopCheck">
      <div v-if="bannerOptionHtml != null" class="container slider-text-title">
        <h3>
          {{ bannerOptionHtml ?? "" }}
        </h3>
      </div>
      <div class="title-subtitle-container">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 v-html="banner.title"></h1>
            </div>
            <div class="col-12 mt-3">
              <h2 v-html="banner.subtitle"></h2>
            </div>
          </div>
          <div
            v-if="bannerOptionButtonText || bannerOptionButtonText_1"
            class="button-container"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div v-if="bannerOptionButtonText" class="col-lg-6 m-0 p-0">
                  <a :href="bannerOptionButtonUrl">
                    <base-button
                      :caption="bannerOptionButtonText ?? ''"
                      color="sliderGrey"
                    ></base-button>
                  </a>
                </div>
                <div v-if="bannerOptionButtonText_1" class="col-lg-6 m-0 p-0">
                  <a :href="bannerOptionButtonUrl_1">
                    <base-button
                      :caption="bannerOptionButtonText_1 ?? ''"
                      color="sliderGreen"
                    ></base-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        v-if="bannerOptionButtonText || bannerOptionButtonText_1"
        class="button-container"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div v-if="bannerOptionButtonText" class="col-lg-6 m-0 p-0">
              <a :href="bannerOptionButtonUrl">
                <base-button
                  :caption="bannerOptionButtonText ?? ''"
                  color="sliderGrey"
                ></base-button>
              </a>
            </div>
            <div v-if="bannerOptionButtonText_1" class="col-lg-6 m-0 p-0">
              <a :href="bannerOptionButtonUrl_1">
                <base-button
                  :caption="bannerOptionButtonText_1 ?? ''"
                  color="sliderGreen"
                ></base-button>
              </a>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["banner", "isDesktop"],
  data() {
    return {
      isDesktopCheck: true,
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
    };
  },
  computed: {
    bannerOptionHtml() {
      if (this.isDesktopCheck) {
        return this.banner.options.html;
      } else {
        return this.banner.options.html_mobile;
      }
    },
    bannerOptionButtonText() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_text;
      } else {
        return this.banner.options.button_text_mobile;
      }
    },
    bannerOptionButtonText_1() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_text_1;
      } else {
        return this.banner.options.button_text_mobile_1;
      }
    },
    bannerOptionButtonUrl() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_url;
      } else {
        return this.banner.options.button_url_mobile;
      }
    },
    bannerOptionButtonUrl_1() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_url_1;
      } else {
        return this.banner.options.button_url_mobile_1;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.deviceWidth = window.innerWidth;
      this.deviceHeight = window.innerHeight;
      this.isDesktopCheck = this.deviceWidth >= 600;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.main-container {
  left: 35%;
  top: 0;
  max-width: 430px;
  height: 100%;
  position: absolute;
}
.second-container {
  display: flex;
  flex-direction: column;
  min-height: 21rem;
  position: relative;
  height: 100%;
}
.slider-text-title {
  padding: 10px 0px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--kajot-grey);
  border: 2px solid var(--light-green);
  position: relative;
  top: 50px;
}
.title-subtitle-container {
  margin-top: 85px;
  position: relative;
}
h1,
h2,
h3 {
  text-align: center;
  color: var(--kajot-grey);
  text-shadow: 0 0 25px rgb(255 255 255 / 40%);
  margin: 0;
}
h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
}
h2 {
  font-size: 18px;
  font-weight: 500;
}
h3 {
  font-size: 14px;
  font-weight: 600;
}
.button-container {
  z-index: 29;
  /* bottom: 5%; */
  min-width: 100%;
  position: relative;
  /* position: absolute; */
}
</style>
