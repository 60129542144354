<template>
  <base-dashboard-section>
    <template #nameSection
      >{{ translations.affiliate_program.kyc_policy }}
    </template>
    <template #default>
      <p class="text-center m-3" style="line-height: 1.2rem; font-weight: 600">
        {{ translations.myprofile.custom_message_kyc ?? "" }}
      </p>
      <div class="container my-4">
        <div class="row d-flex justify-content-center">
          <div>
            <div
              v-for="kyc in playerInfo.kyc"
              :key="kyc.id"
              :value="kyc.id"
              class="mb-4"
            >
              <div>
                {{ kyc.level }} :
                <span class="badge bg-primary">{{ kyc.status }}</span>
                <button
                  v-if="kyc.status != 'accepted'"
                  class="border-0 rounded-1 d-block text-center px-3 input"
                  @click="getNewAccessToken(kyc.level)"
                >
                {{translations.myprofile.verify ?? 'Verify' }}
                </button>
                <hr />
              </div>
            </div>
            <div
              v-if="playerInfo.information.risky_country && moveToLevelSelfie"
            >
              {{translations.myprofile.countryrisk ?? 'The country you live in is at risk. You need to do selfie verification' }}
              <button
                class="border-0 rounded-1 d-block text-center px-3 input"
                @click="moveToLevel(selfieKYCLevel)"
              >
              {{translations.myprofile.verify ?? 'Verify' }}
              </button>
            </div>
          </div>
          <div id="sumsub-websdk-container"></div>
        </div>
      </div>
    </template>
  </base-dashboard-section>
</template>

<script>
import snsWebSdk from "@sumsub/websdk";

import { mapGetters } from "vuex";
export default {
  computed: {
    playerInfo() {
      return this.$store.getters["player/playerInfo"];
    },
    ...mapGetters("player", [
      "token",
      "isAuthenticated",
      "isPlayerLoading",
      "playerInfo",
      "selfieKYCLevel",
      "kyc_token",
    ]),
    ...mapGetters("device", ["isDesktop", "isTablet", "isMobile"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
  },
  data() {
    return {
      moveToLevelSelfie: 1,
    };
  },
  methods: {
    async verifyStatus() {
      this.playerInfo.kyc.forEach((value) => {
        if (
          value.status == "initialized" ||
          value.status == "pending" ||
          value.level == "ID_SELFIE_2NDID_POA"
        ) {
          // level in pending // selfie è stato gia inizialiazzato
          this.moveToLevelSelfie = 0;
          return;
        }

        console.log(value);
      });
    },
    async launchWebSdk(level, applicantEmail = null, applicantPhone = null) {
      let accessToken = this.kyc_token;

      let snsWebSdkInstance = snsWebSdk
        .init(accessToken, () => this.getNewAccessToken(level))
        .withConf({
          lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          uiConf: {},
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        .on("idCheck.stepCompleted", (payload) => {
          console.log("stepCompleted", payload);
        })
        .on("idCheck.onError", (error) => {
          console.log("onError", error);
        })
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch("#sumsub-websdk-container");
    },
    async getNewAccessToken(level) {
      this.$store.dispatch("player/callAccessTokensKyc", level).then(() => {
        return this.launchWebSdk(
          level,
          this.playerInfo.email,
          this.playerInfo.mobile
        );
      });
    },
    async moveToLevel(level) {
      this.$store.dispatch("player/callMoveLevelKyc", level).then(() => {});
    },
  },

  created() {
    let scripts = [
      "https://static.sumsub.com/idensic/static/sns-websdk-builder.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },
  mounted() {
    this.verifyStatus();
  },
};
</script>

<style scoped>
input:disabled:hover {
  cursor: not-allowed;
}
input:disabled:hover {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input {
  padding: 5px 0px;
  background: var(--light-orange);
  border: 2px solid var(--dark-orange);
  border-radius: 10px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
</style>
