import router from '@/router';

export default {
	namespaced: true,
	state() {
		return {
			path: {
				casino: 'casino/play/',
				realityCheck: 'me/mga/realityCheck',
			},
			default_demo_currency: 'EUR',
			currentGameName: null,
			currentGameId: null,
			currentProviderId: null,
			currentGamePath: null,
			isGameLoading: true,
			isForReal: true,
			demoNotAvailable: false,
			demoNotAvailableMessage: '',
			isBalanceInsufficient: false,
			isKYCError: false,
			isSelfExclusionError: false,
		};
	},
	mutations: {
		launchGame(state, payload) {

			state.currentGameName = payload.name;
			state.currentGameId = payload.game_id;
			state.currentProviderId = payload.provider_id;
			state.isGameLoading = false;

			// IF PLAY FOR FUN && PLAY FOR REAL IS NULL
			if (payload.responseData == null) {
				state.demoNotAvailable = true;
				state.demoNotAvailableMessage = 'GAME NOT AVAILABLE';
				return;
			}

			// IF BALANCE IS INSUFFICIENT
			if (
				Object.prototype.hasOwnProperty.call(payload.responseData, 'response')
			) {
				state.demoNotAvailable = true;
				state.isBalanceInsufficient = true;
				state.demoNotAvailableMessage =
					payload.responseData.response.data.message;

				return;
			}

			if (Object.prototype.hasOwnProperty.call(payload.responseData, 'message')
				&&
				(payload.responseData.message.includes('Cannot read properties of undefined') ||
					payload.responseData.message.includes('Provider is not enabled or not')
				)

			) {
				state.demoNotAvailable = true;
				state.demoNotAvailableMessage = 'GAME NOT AVAILABLE';
				return;
			}

			// IF PLAY FOR FUN IS NOT AVAILABLE
			if (payload.responseData.includes('is not available')) {
				state.demoNotAvailable = true;
				state.demoNotAvailableMessage = payload.responseData;
				return;
			}

			state.currentGamePath = payload.responseData;
		},
	},

	actions: {
		play({ state, getters, dispatch }, payload) {


			state.currentGameName = null;
			state.currentGameId = null;
			state.currentProviderId = null;
			state.currentGamePath = null;
			state.demoNotAvailable = false;
			state.demoNotAvailableMessage = '';
			state.isGameLoading = true;
			if (getters.isAuthenticated) {

				state.isForReal = true;
				dispatch('play_real', payload);
			} else {

				state.isForReal = false;
				dispatch('play_demo', payload);
			}

		},

		async play_real(
			{ state, getters, commit },
			{ provider_slug, provider_id, game_id, name }
		) {
			const player = getters.playerInfo;

			// CHECKING PEP STATUS

			if (player.is_pep || player.is_ubo) {
				const playerKYCs = player.kyc;
				const isKYCExist = playerKYCs.length > 0;
				if (isKYCExist) {
					const playerKYC = playerKYCs.find(
						(kyc) => kyc.level == getters.basicKYCLevel
					);
					if (playerKYC.status != 'accepted') {
						alert(
							'Your KYC status is not valid. You will be redirect to KYC page'
						);
						state.isKYCError = true;
						router.replace('/kyc');
						return;
					}
				} else {
					alert(
						'Your KYC status is not valid. You will be redirect to KYC page'
					);
					state.isKYCError = true;
					router.replace('/kyc');
					return;
				}
			}

			// CHECKING SELF EXCLUSION
			const playerExclusionNull = getters.playerInfo.status_expires_at;

			const today = new Date().toISOString();
			if (playerExclusionNull != null) {
				const playerExclusion = new Date(playerExclusionNull).toISOString();

				if (playerExclusion > today) {
					state.isSelfExclusionError = true;
					alert('Your player account is disable until to ' + getters.playerInfo.status_expires_at);
					router.replace('/');
				}
			}

			// CHECK RISKY COUNTRY AND SELFIE
			const playerRiskyCountry = getters.playerInfo.information.risky_country;
			if (playerRiskyCountry == 1) {


				if (player.statusKyc != 'idApproved') {
					alert('Your KYC status is not valid. You will be redirect to KYC page');
					state.isKYCError = true;
					router.replace('/kyc?lv=selfie');
					return;
				}
				/* 
				const playerKYCs = player.kyc;
				 
				const isKYCExist = playerKYCs.length > 0;
				if (isKYCExist) {
					const playerKYC = playerKYCs.find(
						(kyc) => kyc.level == getters.selfieKYCLevel
					);

					if (playerKYC == null || playerKYC.status != 'accepted') {
						alert(
							'You have to complete your verification before play. You will be redirect to KYC Page'
						);
						state.isKYCError = true;
						router.replace('/kyc?lv=selfie');
						return;
					}
				} else {
					alert(
						'Your KYC status is not valid. You will be redirect to KYC page'
					);
					state.isKYCError = true;
					router.replace('/kyc?lv=selfie');
					return;
				} */

			}
			state.currentGameId = game_id;
			state.currentProviderId = provider_id;
			let body = {
				game_id: game_id,
				provider_name: provider_slug,
				provider_id: provider_id,
				is_mobile: getters.mobileStatus,
				is_desktop: getters.desktopStatus,
				lobby_url: window.location.origin,
				skin_id: getters.refId,
				ip_address: getters.clientIp ?? '0.0.0.0',
				user_rank: 0,
				bonus_type: 0,
				bonus_info: 0,
				bonus_target: 0,
				bonus_counter: 0,
				user_id: getters.playerInfo.user_id,
				user_language: getters.language_selected,
				user_currency: getters.playerInfo.currency.code,
			};


			const responseData = await getters.axios
				.post(
					getters.endpoint + state.path.casino + provider_slug,
					body,
					getters.auth
				)
				.then((response) => response.data.data.play_for_real)
				.catch((err) => err);
			commit('launchGame', { responseData, name });
		},
		async play_demo(
			{ state, getters, commit },
			{ provider_slug, provider_id, game_id, name }
		) {
			let body = {
				is_demo: 'true',
				game_id: game_id,
				provider_id: provider_id,
				user_language: getters.language_selected,
				user_currency: state.default_demo_currency,
			};

			const responseData = await getters.axios
				.post(
					getters.endpoint + state.path.casino + provider_slug,
					body,
					getters.auth
				)
				.then((response) => response.data.data.play_for_fun)
				.catch((err) => err);
			commit('launchGame', { responseData, name });
		},
		async realityCheck({ state, getters }, payload) {
			let body = {
				minutes: payload
			};
			let url = getters.endpoint + state.path.realityCheck;
			const responseData = await getters.axios
				.post(
					url,
					body,
					getters.tokenAuth
				)
				.then((response) => response)
				.catch((err) => err);
			console.log(responseData);

		},
		stopGame({ state }) {
			state.currentGameName = null;
			state.currentGamePath = null;
		}
	},
	getters: {
		endpoint(_, _1, _2, rootGetters) {
			return rootGetters['api/endpoint'];
		},
		axios(_, _1, _2, rootGetters) {
			return rootGetters['api/axios'];
		},
		clientIp(_, _1, _2, rootGetters) {
			return rootGetters['api/clientIp'];
		},
		auth(_, _1, _2, rootGetters) {
			return rootGetters['api/auth'];
		},
		playerInfo(_, _1, _2, rootGetters) {
			return rootGetters['player/playerInfo'];
		},
		isAuthenticated(_, _1, _2, rootGetters) {
			return rootGetters['player/isAuthenticated'];
		},
		basicKYCLevel(_, _1, _2, rootGetters) {
			return rootGetters['player/basicKYCLevel'];
		},
		selfieKYCLevel(_, _1, _2, rootGetters) {
			return rootGetters['player/selfieKYCLevel'];
		},
		tokenAuth(_, _1, _2, rootGetters) {
			return rootGetters['player/tokenAuth'];
		},
		desktopStatus(_, _1, _2, rootGetters) {
			return rootGetters['device/desktopStatus'];
		},
		mobileStatus(_, _1, _2, rootGetters) {
			return rootGetters['device/mobileStatus'];
		},
		refId(_, _1, _2, rootGetters) {
			return rootGetters['skinSettings/refId'];
		},
		language_selected(_, _1, _2, rootGetters) {
			return rootGetters['translations/language_selected'];
		},
		currentGameName(state) {
			return state.currentGameName;
		},
		currentGameId(state) {
			return state.currentGameId;
		},
		currentProviderId(state) {
			return state.currentProviderId;
		},
		currentGamePath(state) {
			return state.currentGamePath;
		},
		isGameLoading(state) {
			return state.isGameLoading;
		},
		isForReal(state) {
			return state.isForReal;
		},
		demoNotAvailable(state) {
			return state.demoNotAvailable;
		},
		demoNotAvailableMessage(state) {
			return state.demoNotAvailableMessage;
		},
		isBalanceInsufficient(state) {
			return state.isBalanceInsufficient;
		},
		isKYCError(state) {
			return state.isKYCError;
		},
		isSelfExclusionError(state) {
			return state.isSelfExclusionError;
		},
	},
};
