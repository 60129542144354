export default {
   data() {
      return {
         isButtonClicked: false,
      };
   },
   methods: {
      avoidAnotherClick() {
         this.isButtonClicked = !this.isButtonClicked;
         if (this.isButtonClicked) {
            setTimeout(this.avoidAnotherClick, 3000);
         }
      },
   },
};