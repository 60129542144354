<template>
	<transition name="fade">
		<div v-if="!isTranslationLoading && !isPlayerLoading && !isCasinoLoading">
			<header-desktop
				:isGamePage="isGamePage"
				:translations="translations"
				:isAuthenticated="isAuthenticated"
				:playerInfo="playerInfo"
				:isTop="isTop"
				v-if="isDesktop && isDesktopSize"
			></header-desktop>
			<header-mobile
				v-if="(!isDesktopSize || !isDesktopSize) && !isGamePage"
				:isGamePage="isGamePage"
				:translations="translations"
				:isAuthenticated="isAuthenticated"
				:playerInfo="playerInfo"
				:isTop="isTop"
			></header-mobile>
			<transition name="fade">
				<scroll-to-top-button
					v-if="isBottom"
					@click="scrollToTop"
				></scroll-to-top-button>
			</transition>
		</div>
	</transition>
</template>

<script>
	import { mapGetters } from 'vuex';
	import HeaderDesktop from './Headers/HeaderDesktop.vue';
	import HeaderMobile from './Headers/HeaderMobile.vue';
	import ScrollToTopButton from './ScrollToTopButton.vue';
	export default {
		components: {
			HeaderDesktop,
			HeaderMobile,
			ScrollToTopButton,
		},
		data() {
			return {
				isGamePage: false,
				isTop: true,
				isBottom: false,
			};
		},
		watch: {
			$route() {
				this.disableHeader();
			},
		},
		methods: {
			disableHeader() {
				const page = this.$route.name;
				if (page == 'game-page') {
					this.isGamePage = true;
				} else {
					this.isGamePage = false;
				}
			},
			windowScroll() {
				if (window.scrollY >= 300) {
					this.isTop = false;
				} else {
					this.isTop = true;
				}
			},
			checkPagePosition() {
				if (window.scrollY >= 800) {
					this.isBottom = true;
				} else {
					this.isBottom = false;
				}
			},
			scrollToTop() {
				document.documentElement.scrollTop = 0;
			},
		},
		computed: {
			...mapGetters('device', [
				'isDesktop',
				'isTablet',
				'isMobile',
				'isDesktopSize',
			]),
			...mapGetters('player', [
				'isAuthenticated',
				'playerInfo',
				'isPlayerLoading',
			]),
			...mapGetters('translations', ['translations', 'isTranslationLoading']),
			...mapGetters('casino', ['isCasinoLoading']),
		},
		created() {
			this.disableHeader();
		},
		mounted() {
			window.addEventListener('scroll', () => {
				this.windowScroll();
				this.checkPagePosition();
			});
		},
	};
</script>
