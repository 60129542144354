<template>
	<div class="container mt-5">
		<div class="row">
			<div class="col-12">
				<div class="table-responsive table-reports-download">
					<DataTable
						:data="records"
						:columns="columns"
						class="table table-striped table-bordered display"
						:options="{
							responsive: isDesktop,
							autoWidth: !isDesktop,
							dom: 'Brtp',
							language: {
								zeroRecords: translations.reports.Ooops_search,
								paginate: {
									first: translations.buttons.first,
									previous: translations.buttons.previous,
									next: translations.buttons.next,
									last: translations.buttons.last,
								},
							},

							buttons,
						}"
					>
						<thead>
							<tr>
								<th scope="col">ID</th>
								<th scope="col">Created at (UTC Timezone)</th>
								<th scope="col">Type</th>
								<th scope="col">Status</th>
								<th scope="col">{{ translations.account_balance.Description}}</th>
								<th scope="col">{{ translations.account_balance.Amount}}</th>
								<th scope="col">{{ translations.account_balance.Balance}}</th>
							</tr>
						</thead>
					</DataTable>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import DataTable from 'datatables.net-vue3';
	import DataTableLib from 'datatables.net-bs5';
	import Buttons from 'datatables.net-buttons-bs5';
	import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5';
	import pdfMake from 'pdfmake';
	import pdfFonts from 'pdfmake/build/vfs_fonts';
	pdfMake.vfs = pdfFonts.pdfMake.vfs;
	import 'datatables.net-responsive-bs5';
	import JsZip from 'jszip';
	window.JSZip = JsZip;
	DataTable.use(DataTableLib);
	DataTable.use(pdfMake);
	DataTable.use(ButtonsHtml5);

	export default {
		props: ['casinoList', 'translations', 'skinName', 'isDesktop'],
		components: { DataTable },
		data() {
			return {
				useless: Buttons,
				records: [],
				columns: [
					{ data: 'id' },
					{
						data: null,
						render: function (data) {
							return `${data.created_at.slice(0, 10)}  ${data.created_at.slice(
								11,
								19
							)}`;
						},
					}, 
					{ data: 'type.name' },
					{ data: 'status' },
					{ data: 'type.description' },
					{
						data: null,
						render: function (data) {
							return `${data.currency.symbol} ${(data.amount * 1).toFixed(2)}`;
						},
					}, 
					{
						data: null,
						render: function (data) {
							return `${data.currency.symbol} ${(data.balance * 1).toFixed(2)}`;
						},
					}
				],
				buttons: [
					{
						title: `${this.translations.dashboard_menu.Reports} ${this.skinName}`,
						extend: 'excelHtml5',
						text: '<i class="bi bi-file-earmark-spreadsheet"></i> Excel/CSV',
						className: 'btn-reports-casino btn-excel',
					},
					{
						title: `${this.translations.dashboard_menu.Reports} ${this.skinName}`,
						extend: 'pdfHtml5',
						text: '<i class="bi bi-filetype-pdf"></i> PDF',
						className: 'btn-reports-casino btn-pdf',
					},
					{
						title: `${this.translations.dashboard_menu.Reports} ${this.skinName}`,
						extend: 'copy',
						text: `<i class="bi bi-clipboard-plus"></i> ${this.translations.buttons.copy}`,
						className: 'btn-reports-casino btn-copy',
					},
				],
			};
		},
		computed: {},
		methods: {
			iterateRecords() {
				 
				if (this.casinoList != null) {
					const records = this.casinoList;
				 
					records.forEach((record) => {
						this.records.push(record);
					});
				}
			},
		},
		mounted() {
			this.iterateRecords();
		},
	};
</script>

<style>
	@import 'datatables.net-bs5';
	.table-reports-download td {
		text-align: center;
	}
	.btn-reports-casino {
		margin: 15px auto;
		padding: 5px 10px;
		border-radius: 5px;
		border: 1px solid;
	}
	button.btn-reports-casino > span,
	button.btn-reports-casino > span > i {
		color: var(--dirty-white);
		font-weight: 500;
	}
	.btn-excel {
		background: var(--darker-green);
	}
	.btn-pdf {
		background: var(--dark-red);
	}
	.btn-copy {
		background: var(--kajot-grey);
	}
	li.page-item.active > a.page-link {
		background: var(--light-orange);
		border-color: var(--extralight-grey);
	}
</style>
