// DECLARATION ON API CALLS
import axios from 'axios';
export default {
	namespaced: true,
	state() {
		return {
			buffer: require('buffer/').Buffer,
			// refId: '4011',
			// skinPassword: 'VqvY224R8oWgFaVK',
			// endpoint: '/v1/',
			// urlBo: 'https://stage-bo.theearplatform.com/'
			refId: process.env.VUE_APP_REFID,
			skinPassword: process.env.VUE_APP_SKINPASSWORD,
			endpoint: process.env.VUE_APP_APIENDPOINT,
			axios: axios,
			auth: '',
			clientIp: null,
			urlBo: process.env.VUE_APP_URLBO,
		};
	},
	mutations: {
		createAuth(state) {
			let authentication = {
				login: state.refId,
				password: state.skinPassword,
			};
			state.auth = {
				headers: {
					authorization:
						'Basic ' +
						state.buffer
							.from(
								`${authentication.login}:${authentication.password}`,
								'utf-8'
							)
							.toString('base64'),
				},
			};
		},
		setClientIp(state, payload) {

			if (payload != undefined) {
				state.clientIp = payload.data.ip;
			} else {
				state.clientIp = "0.0.0.0";
				console.log("There's a problem with the IP");
			}
		},
	},
	actions: {
		createAuth(context) {
			context.commit('createAuth');
		},
		async getClientIp({ state, commit }) {
			const responseIp = await state
				.axios('https://api.ipify.org?format=json')
				.then((response) => response)
				.catch((err) => console.log(err));
			commit('setClientIp', responseIp);
		},
	},
	getters: {
		refId(state) {
			return state.refId;
		},
		skinPassword(state) {
			return state.skinPassword;
		},
		endpoint(state) {
			return state.endpoint;
		},
		axios(state) {
			return state.axios;
		},
		auth(state) {
			return state.auth;
		},
		clientIp(state) {
			return state.clientIp;
		},
	},
};
