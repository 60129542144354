// import router from "@/router";

export default {
  namespaced: true,
  state() {
    return {
      path: {
        allGames: "casino/games?",
        games: "casino/games?page=",
        gameSearched: "casino/games?is_provider_visible=1&name=",
        sections: "casino/sections",
        // game_section: "casino/games?&section_id=",
        game_section: "casino/games?&section_id=",
        providers: "casino/providers",
        filterdProviders: "casino/games?",
        gamePageStart: "static/casinoGames/",
      },
      howManyGamesPerPage: 48,
      currentPage: 1,
      //prova last_page
      last_page: false,
      isDevice: "",
      reloadCount: 0,

      // FILTERING
      selectedProvider: null,
      nameSelectedProvider: null,
      selectedSection: null,
      selectedGames: [],
      game_detail: {},
      gamesToBeSearched: null,
      wordToSearch: null,

      // AVAILABLE GAMES IN THE ACTUAL SECTION
      gamesInThisSection: null,

      // TO CHECK
      oldPageLoaded_games: null,
      newPageLoaded_games: null,

      //KAJOT PRIME
      kajotPrime_sections: null,
      kajotPrime_allGames: null,
      // CASINO
      casino_allGames: null,
      casino_sections: null,

      // LIVE CASINO
      liveCasino_allGames: null,
      liveCasino_sections: null,

      // BINGO
      bingo_allGames: null,
      bingo_sections: null,

      // OTHERS
      others_allGames: null,
      others_sections: null,

      // SCRATCH CARDS
      scratchCards_allGames: null,
      scratchCards_sections: null,

      // CRASH GAMES
      crashGames_allGames: null,
      crashGames_sections: null,

      // SKIN GAMES
      allSkinSections: null,
      allSkinGames: null,
      allSkinProviders: null,
      allSkinProvidersForReports: null,
      isLoadingCardGames: true,
      isChangePage: false,
      isCasinoLoading: true,
      isAllSkinGamesLoading: true,
      allSkinGames_loaded: false,
      isFirstLoading: true,
      reels_games_selected: null
    };
  },
  getters: {
    endpoint(_, _1, _2, rootGetters) {
      return rootGetters["api/endpoint"];
    },
    axios(_, _1, _2, rootGetters) {
      return rootGetters["api/axios"];
    },
    auth(_, _1, _2, rootGetters) {
      return rootGetters["api/auth"];
    },
    token(_, _1, _2, rootGetters) {
      return rootGetters["player/token"];
    },
    tokenAuth(_, _1, _2, rootGetters) {
      return rootGetters["player/tokenAuth"];
    },
    isDesktop(_, _1, _2, rootGetters) {
      return rootGetters["device/isDesktop"];
    },
    language_selected(_, _1, _2, rootGetters) {
      return rootGetters["translations/language_selected"];
    },
    isDevice(state, getters) {
      if (getters.isDesktop) {
        return (state.isDevice = "&is_desktop=1");
      } else {
        return (state.isDevice = "&is_mobile=1");
      }
    },
    // GAMES IN SECTIONS
    kajotPrime_sections(state) {
      return state.kajotPrime_sections;
    },
    casino_sections(state) {
      return state.casino_sections;
    },
    liveCasino_sections(state) {
      return state.liveCasino_sections;
    },
    bingo_sections(state) {
      return state.bingo_sections;
    },
    game_detail(state) {
      return state.game_detail;
    },
    scratchCards_sections(state) {
      return state.scratchCards_sections;
    },
    others_sections(state) {
      return state.others_sections;
    },
    last_page(state) {
      return state.last_page;
    },
    crashGames_sections(state) {
      return state.crashGames_sections;
    },
    // ALL GAMES FOR PAGE
    casino_allGames(state) {
      return state.casino_allGames;
    },
    liveCasino_allGames(state) {
      return state.liveCasino_allGames;
    },
    bingo_allGames(state) {
      return state.bingo_allGames;
    },
    scratchCards_allGames(state) {
      return state.scratchCards_allGames;
    },
    others_allGames(state) {
      return state.others_allGames;
    },
    craschGames_allGames(state) {
      return state.craschGames_allGames;
    },
    // SELECTED GAMES DEPENDENT ON PAGE
    selectedSection(state) {
      return state.selectedSection;
    },
    selectedGames(state) {
      return state.selectedGames;
    },
    gamesToBeSearched(state) {
      return state.gamesToBeSearched;
    },
    sectionsAndGamesInThisPage(state) {
      return state.sectionsAndGamesInThisPage;
    },
    gamesInThisSection(state) {
      return state.gamesInThisSection;
    },
    selectedProvider(state) {
      return state.selectedProvider;
    },
    nameSelectedProvider(state) {
      return state.nameSelectedProvider;
    },
    isLoadingCardGames(state) {
      return state.isLoadingCardGames;
    },
    isChangePage(state) {
      return state.isChangePage;
    },
    isCasinoLoading(state) {
      return state.isCasinoLoading;
    },
    allSkinProviders(state) {
      return state.allSkinProviders;
    },
    allSkinProvidersForReports(state) {
      return state.allSkinProvidersForReports;
    },
    // ONLY FOR LAUNCH GAME PAGE
    allSkinSections(state) {
      return state.allSkinSections;
    },
    allSkinGames(state) {
      return state.allSkinGames;
    },
    isAllSkinGamesLoading(state) {
      return state.isAllSkinGamesLoading;
    },
    allSkinGames_loaded(state) {
      return state.allSkinGames_loaded;
    },
    reels_games_selected(state) {
      return state.reels_games_selected
    }
  },
  mutations: {
    //prova
    setLastPage(state, payload) {
      state.last_page = payload;
    },
    setAllSkinGames(state, payload) {
      state.allSkinGames = payload;
      state.allSkinGames_loaded = true;
      state.isAllSkinGamesLoading = false;
    },
    setLaunchGames(state, payload) {
      state.game_detail = payload;
      state.allSkinGames_loaded = true;
      state.isAllSkinGamesLoading = false;
    },
    setSkinProviders(state, payload) {
      let response = payload;
      state.allSkinProviders = response
    },
    setAllSKinProvidersForReports(state, payload) {
      let response = payload;
      state.allSkinProvidersForReports = response
      console.log('*******')
      console.log(state.allSkinProvidersForReports)
      console.log('*******')
    },
    setCasinoLoaded(state) {
      state.isCasinoLoading = false;
    },
    saveSkinSections(state, payload) {
      state.allSkinSections = payload;
    },
    // PLAYER SEARCHING FILTER
    setGamesToDisplay(state, payload) {
      if (payload.data != null) state.selectedGames = payload.data;
      else if (!state.isChangePage) state.selectedGames = payload;
      state.isLoadingCardGames = false;
      state.isChangePage = false;
    },
    setGamesToBeSearched(state, payload) {
      // state.gamesToBeSearched = payload;
      if (payload.data != null) state.gamesToBeSearched = payload.data;
      else if (!state.isChangePage) state.gamesToBeSearched = payload;
    },
    setSelectedProvider(state, providerId) {
      state.filterdProviderId = providerId;
    },
    clearFilter(state, payload) {
      state.selectedGames = payload;
    },
    setReelsToShow(state, value) {
      state.reels_games_selected = value
    }
  },
  actions: {
    async callAllGames({ state, getters, commit }) {
      const responseData = await getters.axios
        .get(getters.endpoint + state.path.allGames, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);
      commit("setGamesToDisplay", responseData);
    },

    async launchGamePage({ state, getters, commit }, { game_id, provider_id }) {
      //ver prec
      const endpoint = getters.endpoint;
      const path = state.path.gamePageStart;
      const url = `${endpoint}${path}${game_id}?provider_id=${provider_id}`;
      const responseData = await getters.axios
        .get(url, getters.auth)
        .then((response) => response.data.data)
        .catch((err) => err);
      commit("setLaunchGames", responseData);
    },

    //TODO E qui //MODIFICATA
    async callSkinGames({ state, getters, commit }) {
      state.isAllSkinGamesLoading = true;
      const responseData = await getters.axios
        .get(
          getters.endpoint + state.path.allGames + getters.isDevice,
          getters.auth
        )
        .then((response) => response)
        .catch((err) => err);
      commit("setAllSkinGames", responseData);
      commit("setCasinoLoaded");
    },

    async callSingleProvider(
      { state, getters, commit },
      { slug = null, currentSection, searchTerm = null }
    ) {
      // Rimuovi la classe "active" dai pulsanti

      /*let elems = document.querySelectorAll(".game-section.active");
      [].forEach.call(elems, function (el) {
        el.classList.remove("active");
      });
      */
      const getGameType = (currentSection) => {
        const pathSegments = currentSection.split("/");
        const gameType = pathSegments[pathSegments.length - 1];

        if (gameType === "live-casino") {
          return "live-casino";
        } else if (gameType === "scratch-cards") {
          return "scratch-card";
        } else if (gameType === "crash-games") {
          return "crash-game";
        }
        return null;
      };

      const gameType = getGameType(currentSection);

      let typeParam = "";

      if (Array.isArray(gameType)) {
        typeParam = gameType.map((type) => "type[]=" + type).join("&");
      } else if (gameType) {
        typeParam = "type=" + gameType;
      }

      let endpoint = getters.endpoint + state.path.filterdProviders;

      if (searchTerm && searchTerm !== "" && slug) {
        endpoint =
          getters.endpoint +
          state.path.gameSearched +
          searchTerm +
          "&provider_id=" +
          slug;
      } else if (searchTerm && searchTerm !== "") {
        endpoint = getters.endpoint + state.path.gameSearched + searchTerm;
      } else if (slug) {
        endpoint += "provider_id=" + slug;
      }

      if (typeParam) {
        endpoint += "&" + typeParam;
      }
      if (state.selectedSection != null)
        endpoint += "&section_id=" + state.selectedSection + "&sort_by=rank";

      const responseData = await getters.axios
        .get(endpoint, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);

      this.selectProvider = responseData;
      state.last_page = true;

      commit("setGamesToDisplay", responseData);
    },

    async callSkinProviders({ state, getters, commit }, filters) {
      let params = "?";
      if (filters != null) {
        filters.forEach((filter, index) => {
          params += `${filter.label}=${filter.value}`;
          if (index < filters.length - 1) {
            params += "&";
          }
        });
      }
      const responseData = await getters.axios
        .get(getters.endpoint + 'casino/sections/' + state.selectedSection + '/providers' + params, getters.auth)
        .then((response) => response.data.data)
        .catch((err) => err);
      commit("setSkinProviders", responseData);
    },

    //for report
    async callAllSkinProvider({ state, getters, commit }, filters) {
      let params = "?";
      if (filters != null) {
        filters.forEach((filter, index) => {
          params += `${filter.label}=${filter.value}`;
          if (index < filters.length - 1) {
            params += "&";
          }
        });
      }

      params += (filters ? "&" : "") + "is_visible=1";

      const responseData = await getters.axios
        .get(getters.endpoint + state.path.providers + params, getters.auth)
        .then((response) => response.data.data)
        .catch((err) => err);
      commit("setAllSKinProvidersForReports", responseData);
    },


    async callSkinSections({ state, getters, commit, dispatch }) {
      state.isCasinoLoading = true;
      const responseData = await getters.axios
        .get(getters.endpoint + state.path.sections, getters.auth)
        .then((response) => response.data.data)
        .catch((err) => err);
      commit("saveSkinSections", responseData);
      dispatch("callGamesBySections", responseData);

    },

    viewGamebySelectedReels({ commit, dispatch }, reels_numbers) {
      commit("setReelsToShow", reels_numbers);
      dispatch("filter");
    },

    async callGamesBySections({ state, getters, commit }, payload) {

      state.gamesInThisSection = null;
      state.selectedProvider = "all";
      let providerInput = document.getElementById("provider");
      let button = document.querySelector(".showProviderModal");
      if (button) {
        button.textContent = "Select Provider";
      }
      if (providerInput) providerInput.value = "all";

      let config = {
        howManyAndPage: `&per_page=${state.howManyGamesPerPage}&page=${state.currentPage}&sort_by=rank`,
      };
      let sections = null;
      if (getters.allSkinSections == null) {
        sections = payload;
      } else {
        sections = getters.allSkinSections;
      }
      let gamesInSections = [];
      //set path with language
      /*
      let routerbase = router.options.history.location.replace(
        new RegExp("/" + getters.language_selected, "g"),
        ""
      );*/
      let parts = window.location.pathname.split("/").pop();

      switch (parts) {
        case "prime": {
          await callGameSectionForPage(
            "kajotPrime-",
            "kajotPrime_allGames",
            "kajotPrime_sections"
          );
          break;
        }
        case "live-casino": {
          await callGameSectionForPage(
            "liveCasino-",
            "liveCasino_allGames",
            "liveCasino_sections"
          );
          break;
        }
        case "crash-games": {
          await callGameSectionForPage(
            "crashGames-",
            "crashGames_allGames",
            "crashGames_sections"
          );
          break;
        }
        case "others": {
          await callGameSectionForPage(
            "others-",
            "others_allGames",
            "others_sections"
          );
          break;
        }
        default: {
          await callGameSectionForPage(
            "casino-",
            "casino_allGames",
            "casino_sections"
          );
          break;
        }
      }
      async function callGameSectionForPage(
        startWith,
        allGames_prop,
        pageSection_prop
      ) {
        const controller = new AbortController();
        state.last_page = false;

        const sectionsInPage = Object.values(sections).filter((section) => {
          return (
            section.name.startsWith(startWith)
          );
        });
        state[pageSection_prop] = sectionsInPage;

        const idsSections = sectionsInPage.map((section) => section.id);
        // console.log('idsSections', idsSections)

        // let lang = 'en';
        // if (localStorage.getItem('language'))
        //   lang =  localStorage.getItem('language');

        // config.language = '&language=' + lang;
        if (startWith !== "bingo-") {
          for (let i = 0; i < idsSections.length; i++) {
            let actualID = idsSections[i];
            if (state.selectedSection === null) {
              state.selectedSection = actualID;
            }
            let obj = {};
            obj["categories"] = actualID;
            if (state.isFirstLoading || actualID === state.selectedSection) {
              await getters.axios
                .get(
                  getters.endpoint +
                  state.path.allGames +
                  state.path.game_section +
                  actualID +
                  config.howManyAndPage +
                  // config.language +
                  getters.isDevice,
                  getters.auth,
                  { signal: controller.signal }
                )
                .then((response) => (obj["games"] = response.data))
                .catch((err) => console.log(err));
              // Cancella la richiesta
              controller.abort();
            }
            gamesInSections.push(obj);

            if (state.selectedSection == actualID) {

              if (obj.games.meta.last_page == obj.games.meta.current_page || parseInt(obj.games.meta.per_page) == obj.games.meta.total)
                state.last_page = true;
            }
          }

          if (gamesInSections.length != 0) {
            state[allGames_prop] = gamesInSections.find((section) => {
              return section.categories == state.selectedSection;
            }).games.data;
          }
          state.sectionsAndGamesInThisPage = gamesInSections;
          let mergeResult = state.selectedGames.concat(state[allGames_prop]);
          state.selectedGames = mergeResult;

          state.gamesInThisSection = state[allGames_prop];
        }
        commit("setCasinoLoaded");
      }
      commit("setCasinoLoaded");
    },

    // PLAYER SEARCHING FILTER
    clearSelection({ state }) {
      state.selectedSection = null;
      state.selectedProvider = null;
    },
    selectSection({ state, dispatch }, payload) {
      state.selectedSection = payload;
      dispatch("filter");
    },
    selectProvider({ state, dispatch }, payload) {
      //originale
      state.selectedProvider = payload;
      dispatch("filter");
    },

    //ChangingPage+LastPage
    async changingPage({ state, dispatch }, payload = null) {
      // alert('changingPage')
      state.isLoadingCardGames = true;
      state.last_page = false;
      if (payload.changePage != null) {
        state.isChangePage = true;
      }
      if (payload.currentPage != null) {
        state.currentPage = payload.currentPage;
        if (state.currentPage == 1) {
          state.isLoadingCardGames = true;
        } else {
          state.isLoadingCardGames = false;
        }
      }
      if (!state.isFirstLoading || state.isChangePage == true) {

        await dispatch("callGamesBySections");
      }
      state.isFirstLoading = false;
      dispatch("filter");
    },

    //Originale
    async filter({ state, getters, commit }) {
      state.isLoadingCardGames = false;
      // state.selectedSection = null;
      if (state.selectedSection == null || state.selectedSection == undefined) {
        try {
          state.selectedSection = getters.sectionsAndGamesInThisPage[0].categories;
        } catch (error) {
          console.log(error)
        }
      }
      const sectionID = state.selectedSection;
      const providerID = state.selectedProvider;
      const reels = state.reels_games_selected;
      let selectedSection_games = [];
      let selection = getters.sectionsAndGamesInThisPage.filter(
        (section) => section.categories == sectionID
      );
      selectedSection_games = selection[0].games.data;
      let gamesToDisplay = [];
      if (reels !== null) {
        gamesToDisplay = selectedSection_games.filter(
          (game) => game.reels_numbers == state.reels_games_selected
        );
      } else if (
        providerID == null ||
        providerID == undefined ||
        providerID == "all" || reels == null
      ) {
        gamesToDisplay = selectedSection_games;
      } else {
        gamesToDisplay = selectedSection_games.filter(
          (game) => game.provider_id == providerID
        );
      }
      commit("setGamesToDisplay", gamesToDisplay);
      commit("setGamesToBeSearched", gamesToDisplay);
    },

    async searchGames(
      { state, getters, commit },
      { payload, currentSection, providerParam, sectionsFilters }
    ) {


      state.wordToSearch = payload;
      state.currentPage = 1;

      //const activeSections = document.querySelectorAll(".game-section.active");
      //activeSections.forEach((el) => el.classList.remove("active"));

      commit("setReelsToShow", null);

      let specificReelElement = document.querySelector(".reel-games-btn#all_reel");
      if (specificReelElement) {
        specificReelElement.classList.add("actived");
      }

      const gameTypeMap = {
        "/live-casino": "live-casino",
        "/cs/live-casino": "live-casino",
        "/de/live-casino": "live-casino",
        "/en/live-casino": "live-casino",
        "/sk/live-casino": "live-casino",
        "/hu/live-casino": "live-casino",
        "/pl/live-casino": "live-casino",
        // "/others": ["video-bingo", "scratch-card", "crash-game"],
        // "/de/others": ["video-bingo", "scratch-card", "crash-game"],
        // "/sk/others": ["video-bingo", "scratch-card", "crash-game"],
        // "/cs/others": ["video-bingo", "scratch-card", "crash-game"],
        // "/en/others": ["video-bingo", "scratch-card", "crash-game"],
      };

      const gameType = gameTypeMap[currentSection] || "";
      const isMultipleTypes = Array.isArray(gameType);
      const typeParam = isMultipleTypes
        ? gameType.map((type) => `&type[]=${type}`).join("")
        : `&type=${gameType}`;
      console.log(typeParam);
      let endpoint = `${getters.endpoint}${state.path.gameSearched}${state.wordToSearch}`;

      if (providerParam && providerParam !== "all") {
        endpoint += `&provider_id=${providerParam}`;
      }

      const isLiveCasino = [
        "/live-casino",
        "/en/live-casino",
        "/sk/live-casino",
        "/cs/live-casino",
        "/hu/live-casino",
        "/de/live-casino",
        "/pl/live-casino",
      ].includes(currentSection);



      if (isLiveCasino) {
        endpoint += "&type=live-casino";
      }
      if (state.selectedSection != null)
        endpoint += "&section_id=" + state.selectedSection;
      else if (sectionsFilters != null) {

        endpoint += "&";
        sectionsFilters.forEach((filter, index) => {
          endpoint += `${filter.label}=${filter.value}`;
          if (index < sectionsFilters.length - 1) {
            endpoint += "&";
          }
        });
      }


      const responseData = await getters.axios
        .get(endpoint, getters.auth)
        .then((response) => response.data)
        .catch((err) => err);

      commit("setGamesToDisplay", responseData);
      state.last_page = true;
    },

    clearFilter({ getters, commit }) {
      const payload = getters.gamesInThisSection;
      commit("clearFilter", payload);
    },
  },
};
