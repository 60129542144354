<template>
	<div class="box-cont d-flex justify-content-center align-items-center">
		<i class="bi bi-caret-up-fill"></i>
	</div>
</template>

<style scoped>
	.box-cont {
		width: 5rem;
		height: 3rem;
		position: fixed;
		bottom: 3rem;
		right: 2rem;
		background: var(--light-orange);
		border-radius: 10px;
		box-shadow: var(--light-orange);
		z-index: 85;
		transition: var(--medium-trans);
		border: 2px solid transparent;
	}
	.box-cont:hover {
		background: var(--dark-orange);
		box-shadow: var(--dark-orange);
		cursor: pointer;
		border-color: var(--light-orange);
	}
	i {
		font-size: 1.5rem;
		color: white;
	}
</style>
