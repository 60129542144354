<template>
  <div class="container extended mt-1">
    <div class="row py-lg-4">
      <transition name="fade">
        <div
          v-if="!selectedGamesExist && gamesToShow < 1"
          class="text-error text-center mt-5"
          style="transition-delay: 1s"
        >
          {{ translations.casino.No_game_with_these_criteria }}
        </div>
      </transition>

      <transition name="fade">
        <GameCard
          v-if="gamesToShow != 0 && selectedGamesExist"
          :gamesection="selectedGames"
          :howmany="gamesToShow"
        ></GameCard>
      </transition>
    </div>

    <transition name="fade">
      <div
        v-if="
          !last_page &&
          showLoadMoreButton &&
          selectedGamesExist &&
          gamesToShow > 0
        "
        class="load-more row justify-content-center mt-2 position-relative"
      >
        <div class="col-10 col-md-4">
          <base-button
            @click="loadMore"
            :caption="loadMoreButtonCaption"
            color="orange"
            nomargin="true"
            :class="{ loading: isLoadingGames }"
            addClasses="loadMoreButton"
          ></base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
const GameCard = defineAsyncComponent(() =>
  import("@/components/global/ui/CardGame.vue")
);
import { mapGetters } from "vuex";

export default {
  components: {
    GameCard,
  },
  data() {
    return {
      gamesToShow: null,
      initialGamesToShow: 48,
      gamesToAdd: 50,
      showLoadMoreButton: null,
      currentPage: 1,
      isLoadingGames: false,
    };
  },
  watch: {
    selectedGames(value, oldValue) {
      if (oldValue == null) {
        if (value != oldValue) {
          this.gamesToShow = value.length;
          this.setLoadMoreButton();
          return;
        }
      }
      if (value.length < this.gamesToShow) {
        this.gamesToShow = value.length;
        this.setLoadMoreButton();
      } else if (
        value.length > this.gamesToShow &&
        value.length < this.initialGamesToShow
      ) {
        this.gamesToShow = value.length;
      } else if (value.length == 0) {
        this.gamesToShow = 0;
      } else {
        this.gamesToShow = this.initialGamesToShow;
      }
      if (value.length != oldValue.length) {
        this.setLoadMoreButton();
      }
      if (value.length == oldValue.length && value.length < this.gamesToShow) {
        this.gamesToShow = value.length;
      }
    },
  },
  computed: {
    ...mapGetters("casino", ["selectedGames", "last_page"]),
    ...mapGetters("translations", ["translations"]),
    selectedGamesExist() {
      if (this.selectedGames == null) {
        return false;
      } else if (this.selectedGames.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    loadMoreButtonCaption() {
      if (this.isLoadingGames) {
        return "";
      } else {
        return this.translations.casino.load_more;
      }
    },
  },
  methods: {
    loadMore() {
      this.isLoadingGames = true;
      this.gamesToShow += +this.gamesToAdd;
      const max = this.selectedGames.length;
      if (max < this.gamesToShow) {
        this.gamesToShow = max;
      }
      const currentPage = this.$store.state.casino.currentPage;
      this.$store
        .dispatch("casino/changingPage", {
          currentPage: currentPage + 1,
          changePage: true,
        })
        .then(() => {
          this.isLoadingGames = false;
        });
    },

    setLoadMoreButton() {
      if (!this.selectedGamesExist) {
        return;
      }
      if (
        this.gamesToShow == null &&
        this.selectedGames.length < this.initialGamesToShow
      ) {
        this.gamesToShow = this.selectedGames.length;
        return;
      }
      if (this.selectedGames.length > this.gamesToShow) {
        this.showLoadMoreButton = true;
        this.gamesToShow = this.initialGamesToShow;
      } else {
        this.showLoadMoreButton = false;
        this.gamesToShow = this.selectedGames.length;
      }
    },

    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          entry.target.classList.contains("load-more")
        ) {
          this.loadMore();
        }
      });
    },
  },
  mounted() {
    this.setLoadMoreButton();
    const options = {
      rootMargin: "0px",
      threshold: 1.0,
    };

    this.intersectionObserver = new IntersectionObserver(
      this.handleIntersection,
      options
    );
    const loadMoreButton = document.querySelector(".load-more");
    if (loadMoreButton) {
      this.intersectionObserver.observe(loadMoreButton);
    }
  },
  beforeUnmount() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  },
};
</script>

<style scoped>
@keyframes loadingAnimation {
  0% {
    content: "Loading Games ";
  }
  33% {
    content: "Loading Games .";
  }
  66% {
    content: "Loading Games . .";
  }
  99% {
    content: "Loading Games . . .";
  }
}

@media (min-width: 2100px) {
  .extended {
    max-width: 1800px;
  }
}

.loadMoreButton.loading::after {
  content: "Loading Games ...";
  animation: loadingAnimation 1s infinite;
}
@media (max-width: 480px) {
  .extended {
    margin-top: 20px !important;
  }
}
</style>
