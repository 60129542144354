export default {
	playerLoadingCompleted(state) {
		state.isLoading = false;
	},
	loginPlayer(state, payload) {
		localStorage.setItem(payload.access, payload.codecToken);
		state.token = decodeURIComponent(window.atob(payload.codecToken));
	},
	logoutPlayer(state, payload) {
		// add "if confirmed" execute logout
		const access = window.btoa(encodeURIComponent('___access___'));
		localStorage.removeItem(access);
		location.pathname = '/';
		state.token = payload;
	},
	setTokenOnLoad(state) {
		const access = window.btoa(encodeURIComponent('___access___'));
		if (
			localStorage.getItem(access) != null &&
			localStorage.getItem(access) != undefined
		) {
			const decodecToken = decodeURIComponent(
				window.atob(localStorage.getItem(access))
			);
			state.token = decodecToken;
		}
	},
	setTempToken(state, payload) {
		state.temp_token = payload;
	},
	checkTempToken(state, payload) {
		state.isCheckingToken = false;
		state.isTempTokenValid = payload;
	},
	setUserInformation(state, payload) {
		state.playerInfo = payload.data;
		state.playerBalance = payload.data.balance;
		state.isLoading = false;

		//state.playerCampaign = payload.data.bonus;
	},
	setPlayerBalance(state, payload) {
		state.playerInfo.balance = payload;
	},
	setKycToken(state, payload) {
		state.kyc_token = payload.token;
	},
	setStatisticLimits(state, payload) {

		state.statisticLimits = payload.data;
	},
	setStatisticExclusions(state, payload) {

		state.statisticExclusions = payload.data;
	},
	setUserDocuments(state, payload) {
		state.playerDocuments = payload;
		if (Object.keys(payload.id_card).length > 0) {
			state.playerDocuments['id_card'] = [payload.id_card];
		}
		if (Object.keys(payload.passport).length > 0) {
			state.playerDocuments['passport'] = [payload.passport];
		}
		if (Object.keys(payload.driver_license).length > 0) {
			state.playerDocuments['driver_license'] = [payload.driver_license];
		}
	},
	setUserSurvey(state, payload) {
		state.playerSurvey = payload;
	},
	setUserAml(state, payload) {
		state.playerAml = payload;
	},
	setSurveyAnswers() { },
	// setExclusion(state, payload) {
	// 	state.limitsModalTitle =
	// 		'Your account has been suspended until to ' +
	// 		new Date(payload.time).toISOString().slice(0, 10);
	// },
	setPlayerLimits() {
		//	if (Object.prototype.hasOwnProperty.call(payload, 'data')) {
		// let phrase = '';
		// Object.keys(payload.data).forEach((key) => {
		// 	phrase += payload.data[key][0];
		// });
		// state.XXXXXXXXXXXXX = phrase;
		//	}
	},
	changePlayerPassword(state, payload) {

		if (payload.status != 200)
			state.errorMessage = true;
		else
			state.errorMessage = false;

		if (Object.prototype.hasOwnProperty.call(payload, 'data')) {
			// let phrase = '';
			// Object.keys(payload.data).forEach((key) => {
			// 	phrase += payload.data[key][0];
			// });
			// state.XXXXXXXXXXXXX = phrase;
			// state.showPasswordModal = true;
		}
	},
	sendDocuments(_, payload) {
		if (payload) {
			return;
		}
		window.location.reload();
	},
	messageHasBeenRed(_, payload) {
		if (payload.data == '') {
			return;
		}
	},
	checkOptOutToken(state, payload) {
		state.isCheckingOptOutToken = false;
		state.optOutToken = payload;
	},
	newsletterOptOut(state, payload) {
		state.newsletterOptOut = payload;
	},
	setIsSocketOn(state, value) {
		state.isSocketOn = value;
	}
};
