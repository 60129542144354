<template>
  <base-dashboard-section>
    <template #nameSection>{{ translations.promotion.my_bonus ?? "My Bonus" }}</template>
    <div v-if="campaignUnusedList.length > 0 && bonus.length == 0">
      <h3 class="text-center mb-5">
        {{
          translations.promotion.bonuses_that_can_be_actived ??
          "Bonuses that can be activated"
        }}
      </h3>
      <div class="row">
        <div
          class="col-md-6 col-lg-4 mb-4"
          v-for="(campaignUnused, index) in campaignUnusedList"
          :key="index"
        >
          <div class="card">
            <span class="gift-icon">
              <img src="/images/giftIcon.png" alt="Promotion Image" />
            </span>
            <div class="card-body">
              <h5 class="card-title-unused">
                {{ campaignUnused.name }}
              </h5>
              <div class="information-campaign">
                <div class="promo-infos">
                  <p>
                    <strong>
                      {{ translations.promotion.amount_added ?? "Amount added" }}:</strong
                    >
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{
                      playerInfo.currency.code == "CZK"
                        ? parseFloat(campaignUnused.amount).toFixed(0)
                        : parseFloat(campaignUnused.amount).toFixed(2)
                    }}
                  </p>
                  <p>
                    <strong>
                      {{ translations.promotion.min_dep ?? "Min. deposit" }}:</strong
                    >
                    {{ playerInfo?.currency?.symbol ?? "" }}
                    {{
                      playerInfo.currency.code == "CZK"
                        ? parseFloat(campaignUnused.min_dep).toFixed(0)
                        : parseFloat(campaignUnused.min_dep).toFixed(2)
                    }}
                  </p>

                  <div class="makeDeposit-Line">
                    <span>
                      <strong>{{ translations.promotion.type ?? "Type" }}:</strong>
                      {{ campaignUnused.action.name }}
                    </span>
                    <span
                      @click="redirectDepositPage(campaignUnused.code)"
                      v-show="
                        campaignUnused.action.name.toLowerCase().includes('deposit') &&
                        campaignUnused.is_valid
                      "
                    >
                      <a href="#">
                        {{ translations.promotion.make_deposit ?? "Make Deposit" }}
                      </a>
                    </span>
                  </div>
                  <p>
                    <strong
                      >{{ translations.promotion.bonus_code ?? "Bonus Code" }}:</strong
                    >
                    {{ campaignUnused.code }}
                  </p>

                  <p>
                    <strong>{{ translations.promotion.wagering ?? "Wagering" }}:</strong>
                    {{ campaignUnused.wagering }}
                  </p>

                  <small class="dangeralert" v-if="campaignUnused?.options?.sms_verified">
                    {{
                      translations.promotion.sms_verified ??
                      "To use this promo, please verify your phone number from your profile"
                    }}
                  </small>

                  <p class="mb-0" v-if="!campaignUnused.is_valid">
                    <strong
                      >{{ translations.promotion.valid_from ?? "Valid From" }}:</strong
                    >
                    <br />
                    {{ campaignUnused.valid_from }}
                  </p>
                </div>
                <button
                  v-if="
                    campaignUnused.description !== null &&
                    typeof campaignUnused.description !== 'undefined'
                  "
                  class="show-tc-campaign"
                  @click="showTAndCModal(campaignUnused.description, index)"
                >
                  {{
                    selectedCampaignIndex === index
                      ? translations.promotion.tcHide ?? "T&C (Hide)"
                      : translations.promotion.tc ?? "T&C"
                  }}
                </button>
                <p
                  class="tc-description-campaign"
                  v-if="isTAndCModalVisible && selectedCampaignIndex === index"
                  v-html="termsAndConditions"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="bonus.campaign">
      <div class="row">
        <div class="col-md-12 col-lg-12 mb-4">
          <div class="card">
            <span class="gift-icon">
              <img src="/images/giftIcon.png" alt="Promotion Image" />
            </span>
            <div class="card-body">
              <h5 class="card-title text-uppercase">
                {{ bonus.campaign.name ?? "" }}
              </h5>
              <div class="information-campaign">
                <p>
                  <strong
                    >{{ translations.promotion.amount_added ?? "Amount Added" }}:</strong
                  >
                  {{ playerInfo?.currency?.symbol ?? "" }}
                  {{
                    playerInfo.currency.code == "CZK"
                      ? parseFloat(bonus.campaign.amount).toFixed(0)
                      : parseFloat(bonus.campaign.amount).toFixed(2)
                  }}
                </p>

                <div class="makeDeposit-Line">
                  <span>
                    <strong>{{ translations.promotion.type ?? "Type" }}:</strong>
                    {{ bonus.campaign.action.name }}
                  </span>
                </div>
                <p>
                  <strong
                    >{{ translations.promotion.bonus_code ?? "Bonus Code" }}:</strong
                  >
                  {{ bonus.campaign.code }}
                </p>

                <p>
                  <strong>{{ translations.promotion.wagering ?? "Wagering" }}:</strong>
                  {{ bonus.campaign.wagering }}
                </p>

                <p v-if="bonus.freespins > 0">
                  <strong>{{ translations.promotion.freespins ?? "Freespins" }}:</strong>
                  {{ bonus.freespins }}
                </p>

                <p v-if="bonus.freespins_counter > 0">
                  <strong
                    >{{
                      translations.promotion.freespins_used ?? "Freespins used"
                    }}:</strong
                  >
                  {{ bonus.freespins_counter }}
                </p>
                <p v-if="bonus.freespins > 0 && bonus.campaign.max_bet > 0">
                  <strong
                    >{{
                      translations.promotion.bet_per_freespin ?? "Bet per Freespin"
                    }}:</strong
                  >
                  {{ playerInfo?.currency?.symbol ?? "" }}
                  {{ bonus.campaign.max_bet }}
                </p>

                <div class="mb-2 boxturnover">
                  <p class="mb-0">
                    <b>{{ translations.promotion.please_note ?? "PLEASE NOTE" }}:</b>
                    {{
                      translations.promotion.turnover_requirement ??
                      "The turnover requirement has not been met yet!"
                    }}
                  </p>
                  <p class="mb-0">
                    {{ translations.promotion.wager ?? "Wager" }}:
                    <b>
                      {{ playerInfo?.currency?.symbol ?? "" }}
                      {{ bonus.target }}
                    </b>
                  </p>
                  <p class="mb-0">
                    {{ translations.promotion.missing_wager ?? "Missing Wager" }}
                    :
                    <b>
                      {{ playerInfo?.currency?.symbol ?? "" }}
                      {{ bonus.countdown ?? "" }}
                    </b>
                  </p>
                </div>

                <button
                  v-if="
                    bonus.campaign.description !== null &&
                    typeof bonus.campaign.description !== 'undefined'
                  "
                  class="show-tc-campaign"
                  @click="showTAndCModal(bonus.campaign.description, index)"
                >
                  {{
                    selectedCampaignIndex === index
                      ? translations.promotion.tcHide ?? "T&C (Hide)"
                      : translations.promotion.tc ?? "T&C"
                  }}
                </button>
                <p
                  class="tc-description-campaign"
                  v-if="isTAndCModalVisible && selectedCampaignIndex === index"
                  v-html="termsAndConditions"
                ></p>
                <p class="delete-promotion" @click="deleteCampaign">
                  {{ translations.promotion.delete_promotion ?? "Delete Promotion" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="campaignUnusedList.length == 0 && bonus.length == 0">
      <p class="text-center no-campaign-available">
        {{ translations.promotion.no_campaign_available ?? "No campaign available!" }}
      </p>
    </div>
  </base-dashboard-section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isTAndCModalVisible: false,
      termsAndConditions: null,
      selectedCampaignIndex: -1,
      language_selected: localStorage.getItem("language"),
      bonus: [],
      campaignUnused: [],
    };
  },
  computed: {
    playerInfo() {
      return this.$store.getters["player/playerInfo"];
    },
    ...mapGetters("skinPromotions", [
      "promotionsList",
      "promotion",
      "isPromotionsLoading",
      "isPromoIdLoading",
      "campaignUnusedList",
    ]),
    ...mapGetters("player", [
      "token",
      "isAuthenticated",
      "isPlayerLoading",
      "playerInfo",
    ]),
    ...mapGetters("device", ["isDesktop", "isTablet", "isMobile"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
  },
  methods: {
    redirectDepositPage(bonus_code) {
      this.$store.dispatch("skinPromotions/saveBonusCode", bonus_code);
      this.$router.push({
        name: "deposit",
        params: { lang: this.language_selected },
        query: { code: bonus_code },
      });
    },
    deleteCampaign() {
      this.$store.dispatch(
        "skinPromotions/deleteCampaign",
        this.playerInfo.bonus.player.user_id
      );
    },
    showTAndCModal(description, index) {
      if (index === this.selectedCampaignIndex) {
        // If the clicked campaign is already open, close it
        this.isTAndCModalVisible = false;
        this.selectedCampaignIndex = -1;
      } else {
        // If a different campaign is clicked, open its T&C modal
        this.isTAndCModalVisible = true;
        this.termsAndConditions = description;
        this.selectedCampaignIndex = index;
      }
    },
  },
  created() {
    this.$store.dispatch("skinPromotions/callUnusedCampaign", this.playerInfo.user_id);
    let language = localStorage.getItem("language");
    this.$router.push({ params: { lang: language } });
    if (this.playerInfo && this.playerInfo.bonus && this.playerInfo.bonus) {
      this.bonus = this.playerInfo.bonus;
    }
  },
};
</script>

<style scoped>
.boxturnover {
  border: 1px solid var(--dark-orange);
  border-radius: 15px;
  padding: 10px;
  color: var(--dark-orange);
}
.boxturnover p,
.boxturnover b {
  color: var(--dark-orange);
}
.card-img-top {
  max-width: 100%;
  object-fit: contain;
  max-height: 150px;
}
.card {
  border: 3px solid var(--extralight-grey);
  border-radius: 15px;
  box-shadow: var(--light-shadow);
  position: relative;
}

.promo-infos {
  min-height: 180px;
  margin-bottom: 15px;
}

.card-title {
  font-size: 15px;
  text-align: center;
  min-height: 100px;
  padding: 10px 0px;
  white-space: pre-wrap;
  width: 100%;
  line-height: 1.5rem;
}

.card-title-unused {
  font-size: 15px;
  text-align: center;
  min-height: 100px;
  padding: 10px 0px;
  white-space: pre-wrap;
  width: 80%;
  line-height: 1.5rem;
}

.card-body {
  padding: 0.5rem 1rem;
}
.makeDeposit-Line {
  margin-bottom: 15px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p {
  margin-bottom: 15px;
  text-align: left !important;
}

a {
  font-weight: 600;
  color: var(--dark-orange);
  font-size: 12px;
}

h5 {
  margin-bottom: 0px;
}

.information-campaing {
  padding: 20px 5px;
}

.gift-icon {
  position: absolute;
  top: -5%;
  right: 0%;
}

.gift-icon img {
  max-width: 70px;
  max-height: 70px;
  -o-object-fit: contain;
  object-fit: contain;
}

.no-campaign-available {
  line-height: 1.44;
  font-size: 20px;
  font-weight: 600;
  opacity: 0.8;
}

.show-tc-campaign {
  background: transparent;
  border: none;
  text-decoration: underline;
  color: var(--dark-orange);
  transition: 0.4s all;
  margin-bottom: 5px;
  font-weight: 600;
}

.tc-description-campaign {
  margin: 10px 0px;
  color: rgb(90, 90, 90);
  font-weight: 500;
  line-height: 1.4rem;
  transition: 0.4s all;
}

.delete-promotion {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2rem;
  margin: 5px 0px;
  color: var(--dark-orange);
  text-align: center !important;
  cursor: pointer;
}
.dangeralert {
  color: var(--dark-orange);
  line-height: 20px;
  display: block;
}
@media (max-width: 576px) {
  .card-title {
    text-align: left;
    padding: 10px 5px;
    min-height: 70px;
    width: 80%;
  }

  .card-title-unused {
    text-align: left;
    padding: 10px 5px;
    min-height: 70px;
    width: 80%;
  }

  .modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    max-width: 400px;
    max-height: 75%;
    overflow: auto;
  }
}
</style>
<!-- //modale sopra 
<div v-if="isTAndCModalVisible">
<div class="modal" :class="{ open: isTAndCModalVisible }">
<div class="modal-content">
<div
style="
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
position: sticky;
top: 0px;
 background-color: #fefefe;
 z-index: 100;
"
>
<h4 class="mb-0">T&C</h4>
<span class="close" @click="closeTAndCModal"
 >&times;</span
>
</div>
 <p
 class="tc-description-campaign"
v-html="termsAndConditions"
 ></p>
</div>
</div>
</div>
showTAndCModal(description, index) { if (index === this.selectedCampaignIndex) {
this.isTAndCModalVisible = !this.isTAndCModalVisible; } else {
this.isTAndCModalVisible = true; this.termsAndConditions = description;
this.selectedCampaignIndex = index; } }, closeTAndCModal() {
this.isTAndCModalVisible = false; this.selectedCampaignIndex = -1; }, .modal {
display: none; position: fixed; z-index: 9999; left: 0; top: 0; width: 100%;
height: 100%; background-color: rgba(0, 0, 0, 0.4); opacity: 0; } .open {
display: flex; align-items: center; justify-content: center; opacity: 1; }
.modal-content { background-color: #fefefe; margin: 10% auto; padding: 20px;
border: 1px solid #888; max-width: 400px; max-height: 100%; overflow: auto; }
.close { float: left; font-size: 28px; font-weight: bold; cursor: pointer;
color: var(--dark-orange); }-->
