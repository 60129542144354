<template>
  <div>
    <div v-if="item.length != 0">
      <div v-for="it in items" :key="it.id">
        <div class="container my-2">
          <div class="row justify-content-center">
            <div class="col-6 my-2 my-md-0 col-md-3">
              <div class="text-center">
                <b>{{ translations.myprofile.Idn }}:</b>
                <br />
                {{ it.idn }}
              </div>
            </div>
            <div class="col-6 my-2 my-md-0 col-md-3">
              <div class="text-center">
                <b> {{ translations.registration.document_type }}:</b>
                <br />
                {{ translations.myprofile[it.type] }}
              </div>
            </div>
            <div class="col-6 my-2 my-md-0 col-md-3">
              <div class="text-center">
                <b> {{ translations.myprofile.Created_at }}:</b>
                <br />
                {{ it.created_at }}
              </div>
            </div>
            <div class="col-6 my-2 my-md-0 col-md-3">
              <div class="text-center">
                <b> {{ translations.myprofile.Expires_at }}:</b>
                <br />
                {{ it.expires_at }}
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div
              class="col-5 col-md-3 pt-3"
              v-for="img in it.media"
              :key="img.id"
            >
              <img :src="img.full_url" />
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 pt-3 font-italic pending" v-if="!it.is_verified">
              ...{{
                translations.myprofile.verification_pending ??
                "verification pending"
              }}
            </div>
            <div class="col-12 pt-3 verified" v-else>
              {{
                translations.myprofile.document_successfully ??
                "Document successfully uploaded"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!(i == 'sowq_proof' && item.length > 0)">
      <div class="my-3">
        <div class="container">
          <div class="row my-4 align-self-end justify-content-around">
            <div class="row my-4 justify-content-center">
              <div class="col-12 col-md-6 text-center pb-5 pb-lg-0">
                <div>
                  <label data-use="upload" :for="i"
                    >{{ translations.myprofile.Choose_document.toUpperCase() }}
                    <span
                      class="text-error-small"
                      v-if="theseNeedsBackFile.includes(selectedType)"
                      >({{ translations.myprofile.Front_Side }})</span
                    ></label
                  >
                  <input
                    :id="i"
                    type="file"
                    @change="uploadFrontSide(i)"
                    :ref="i"
                    :accept="parameters.acceptedFiles"
                  />
                </div>
                <div class="pt-2">
                  <div
                    v-if="front_file == null && isFrontFileAccepted == null"
                    class="suggestion text-center"
                  >
                    {{ translations.myprofile.Document_ext_wrong }}
                  </div>
                  <div
                    v-else-if="isFrontFileAccepted"
                    class="text-success-small text-center"
                  >
                    {{ front_file.name }}
                  </div>
                  <div v-else>
                    <div
                      v-if="isFrontFileTooLarge"
                      class="text-error-small text-center"
                    >
                      {{ translations.myprofile.Document_too_large }}
                    </div>
                    <div v-else class="text-error-small text-center">
                      {{ translations.myprofile.Document_ext_wrong }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-12 col-md-6 text-center pb-5 pb-lg-0"
                v-if="theseNeedsBackFile.includes(selectedType)"
              >
                <!-- SHOW SECOND UPLOAD FIELD IF IT IS ID CARD -->
                <div>
                  <label data-use="upload" for="back_side"
                    >{{ translations.myprofile.Choose_document.toUpperCase() }}
                    <span class="text-error-small"
                      >({{ translations.myprofile.Back_Side }})</span
                    >
                  </label>
                  <input
                    id="back_side"
                    type="file"
                    @change="uploadBackSide('back_side')"
                    ref="back_side"
                    :accept="parameters.acceptedFiles"
                  />
                </div>
                <div class="pt-2">
                  <div
                    v-if="back_file == null && isBackFileAccepted == null"
                    class="suggestion text-center"
                  >
                    {{ translations.myprofile.Document_ext_wrong }}
                  </div>
                  <div
                    v-else-if="isBackFileAccepted"
                    class="text-success-small text-center"
                  >
                    {{ back_file.name }}
                  </div>
                  <div v-else>
                    <div
                      v-if="isBackFileTooLarge"
                      class="text-error-small text-center"
                    >
                      {{ translations.myprofile.Document_too_large }}
                    </div>
                    <div v-else class="text-error-small text-center">
                      {{ translations.myprofile.Document_ext_wrong }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-md-6 text-center my-2 pb-5 pb-lg-0">
                <div>
                  <label class="form-label" for="expires_at"
                    >{{ translations.myprofile.Expires_at }}:</label
                  >
                  <input
                    id="expires_at"
                    type="date"
                    class="form-control"
                    v-model="expires_at"
                    :min="tomorrow"
                  />
                </div>
              </div>
            </div>

            <div class="row justify-content-center" v-if="showIdn(i)">
              <div class="col-12 col-md-6 text-center my-2 pb-5 pb-lg-0">
                <div>
                  <label class="form-label" for="idn"
                    >{{ translations.myprofile.Idn }}:</label
                  >
                  <input
                    id="idn"
                    type="text"
                    class="form-control"
                    v-model="idn"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-3 justify-content-center">
              <div class="col-12 col-md-6 text-center my-2">
                <base-button
                  :caption="translations.myprofile.Upload.toUpperCase()"
                  color="orange"
                  :disabled="!isFrontFileAccepted || buttonDisabled"
                  nomargin="true"
                  @click="sendFile"
                ></base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  props: ["i", "item", "translations"],

  data() {
    return {
      front_file: null,
      back_file: null,
      theseNeedsBackFile: ["id_card", "driver_license"],
      isFrontFileAccepted: null,
      isFrontFileTooLarge: false,
      isBackFileAccepted: null,
      isBackFileTooLarge: false,
      expires_at: null,
      idn: null,
      selectedType: null,
      today: new Date().toISOString().slice(0, 10),
      parameters: {
        acceptedFiles: [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "application/pdf",
        ],
        maxFileSize: "2000000",
      },
    };
  },
  computed: {
    ...mapGetters("player", ["selectedDocumentToUpload"]),
    buttonDisabled() {
      if (this.theseNeedsBackFile.includes(this.selectedType)) {
        if (this.isBackFileAccepted && !!this.idn) {
          return false;
        } else {
          return true;
        }
      } else if (this.selectedType == "passport") {
        if (this.idn) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    tomorrow() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().slice(0, 10);
    },
    items() {
      return Object.prototype.hasOwnProperty.call(this.item[0], "id")
        ? this.item
        : this.item[0];
    },
  },
  watch: {
    selectedDocumentToUpload(value) {
      this.deleteDatas(value);
    },
  },
  methods: {
    showIdn(i) {
      switch (i) {
        case "id_card":
          return true;
        case "passport":
          return true;
        case "driver_license":
          return true;
        default:
          return false;
      }
    },
    deleteDatas(id) {
      this.selectedType = id;
      this.front_file = null;
      this.back_file = null;
      this.isFrontFileAccepted = null;
      this.isFrontFileTooLarge = false;
      this.expires_at = this.tomorrow;
      this.idn = null;
    },
    uploadFrontSide(ix) {
      this.front_file = null;
      this.isFrontFileAccepted = false;
      this.isFrontFileTooLarge = false;
      const typeAccepted = this.parameters.acceptedFiles;
      const maxFileSize = this.parameters.maxFileSize;
      const type = this.$refs[ix].files[0].type;
      const name = this.$refs[ix].files[0].name;
      const size = this.$refs[ix].files[0].size;
      const file = this.$refs[ix].files[0];
      for (const i in typeAccepted) {
        if (typeAccepted[i] == type.toLowerCase()) {
          if (+size <= +maxFileSize) {
            return [
              (this.isFrontFileAccepted = true),
              (this.front_file = {
                name,
                size,
                type,
                file,
              }),
            ];
          } else {
            return [
              (this.isFrontFileAccepted = false),
              (this.isFrontFileTooLarge = true),
            ];
          }
        } else {
          continue;
        }
      }
    },
    uploadBackSide(ix) {
      this.back_file = null;
      this.isBackFileAccepted = false;
      this.isBackFileTooLarge = false;
      const typeAccepted = this.parameters.acceptedFiles;
      const maxFileSize = this.parameters.maxFileSize;
      const type = this.$refs[ix].files[0].type;
      const name = this.$refs[ix].files[0].name;
      const size = this.$refs[ix].files[0].size;
      const file = this.$refs[ix].files[0];
      for (const i in typeAccepted) {
        if (typeAccepted[i] == type.toLowerCase()) {
          if (+size <= +maxFileSize) {
            return [
              (this.isBackFileAccepted = true),
              (this.back_file = {
                name,
                size,
                type,
                file,
              }),
            ];
          } else {
            return [
              (this.isBackFileAccepted = false),
              (this.isBackFileTooLarge = true),
            ];
          }
        } else {
          continue;
        }
      }
    },
    sendFile() {
      let selected = this.selectedType ?? this.selectedDocumentToUpload;
      let frontFileName = "";
      let backFileName = "";
      const formData = new FormData();

      if (this.theseNeedsBackFile.includes(this.selectedType)) {
        formData.append("back_file", this.back_file.file);
      }

      formData.append("front_file", this.front_file.file);
      formData.append("expires_at", this.expires_at);
      formData.append("idn", this.idn);
      formData.append("type", selected);

      // Check sul nome del front_file
      if (this.front_file.file instanceof File) {
        frontFileName = this.front_file.file.name;
        if (!/^[a-zA-Z0-9_.-]+$/.test(frontFileName)) {
          Swal.fire({
            text:
              this.translations.dashboard_menu
                .Attention_do_not_insert_special_char ??
              "Please ensure that the names of the files do NOT include any spaces or special characters.",
            confirmButtonColor: "rgb(219, 81, 18)",
          });
          return;
        }
      }

      // Check sul nome del back_file
      if (
        this.theseNeedsBackFile.includes(this.selectedType) &&
        this.back_file.file instanceof File
      ) {
        backFileName = this.back_file.file.name;
        if (!/^[a-zA-Z0-9_.-]+$/.test(backFileName)) {
          Swal.fire({
            text:
              this.translations.dashboard_menu
                .Attention_do_not_insert_special_char ??
              "Please ensure that the names of the files do NOT include any spaces or special characters.",
            confirmButtonColor: "rgb(219, 81, 18)",
          });
          return;
        }
      }

      // Entrambi i file sono corretti
      const contentType = { "Content-Type": "multipart/form-data" };
      this.$store.dispatch("player/sendDocuments", {
        formData,
        contentType,
      });
    },
  },
};
</script>

<style scoped>
.custom-confirm-button {
  background-color: var(--dark-orange) !important;
}
img {
  width: 100%;
  border: 2px solid var(--extralight-grey);
  border-radius: 10px;
  box-shadow: var(--light-shadow);
}
label[data-use="upload"] {
  width: 100%;
  text-align: center;
  padding: 8px 15px;
  border-radius: 5px;
  background: var(--darker-grey);
  border: 1px solid var(--light-grey);
  box-shadow: var(--light-shadow);
  color: white;
  transition: var(--short-trans);
}
label[data-use="upload"]:hover {
  box-shadow: none;
  background: var(--extralight-grey);
  color: var(--darker-grey);
}
input[type="file"] {
  display: none;
}
.suggestion {
  font-style: italic;
  font-size: 0.7rem;
}
.pending {
  text-align: center;
  color: var(--dark-yellow);
  font-style: italic;
  font-size: 1rem;
  font-weight: 500;
}
.verified {
  text-align: center;
  color: var(--darker-green);
  font-size: 1rem;
  font-weight: 600;
}
</style>
