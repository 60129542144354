<template>
	<div
		class="transaction my-4"
		v-for="message in messagesList"
		:key="message.id"
	>
		<div class="row my-3 p-2 d-flex justify-content-center align-items-center">
			<div class="col-12 text-left transaction-time">
				{{ message.created_at.slice(8, 10) }}/{{
					message.created_at.slice(5, 7)
				}}/{{ message.created_at.slice(0, 4) }} -
				{{ message.created_at.slice(11, 19) }}
			</div>
			<div class="col-12 text-left transaction-type">
				{{ message.subject}}
			</div>
			<div class="col-12  pt-2 text-left">
				{{ message.body }}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['messagesList', 'translations'],
		computed: {
	 
		},
	};
</script>

<style scoped>
	.transaction {
		box-shadow: var(--light-shadow);
		border: 2px solid whitesmoke;
		background: var(--extralight2-grey);
		border-radius: 10px;
		transition: var(--short-trans);
		transform: scale(1);
	}
	.transaction:hover {
		transform: scale(1.05);
		cursor: default;
	}
	.transaction-type {
		text-transform: uppercase;
		font-weight: 600;
	}
	.transaction-time {
		font-weight: 600;
		font-size: 0.8rem;
	}
	.transaction-label {
		display: block;
		text-align: center;
		font-size: 0.7rem;
		font-style: italic;
	}
	.initialized {
		color: rgb(47, 128, 187);
		text-transform: capitalize;
	}
	.authorized {
		color: green;
		text-transform: uppercase;
		font-size: 1.2rem;
		font-weight: bolder;
	}
	.declined {
		color: var(--dark-red);
		text-transform: uppercase;
		font-weight: bold;
	}
	.reverted {
		color: goldenrod;
		text-transform: capitalize;
		font-weight: bold;
	}
	.pending {
		color: var(--light-orange);
		font-style: italic;
	}
	.pending::after {
		content: '...';
		color: var(--light-orange);
	}
</style>
