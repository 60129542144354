<template>
  <base-dashboard-section>
    <template #nameSection>{{ translations.dashboard_menu.Reports }}</template>
    <template #default>
      <transition name="fade">
        <div class="container search-module py-1 px-3">
          <div class="row date-field py-3 d-flex justify-content-center">
            <div class="col-6">
              <label class="form-label" for="from_date">{{
                translations.reports.From
              }}</label>
              <input
                v-model="from_date"
                class="form-control"
                id="from_date"
                type="date"
                :max="yesterday"
              />
            </div>
            <div class="col-6">
              <label class="form-label" for="from_date">{{
                translations.reports.To
              }}</label>
              <input
                v-model="to_date"
                class="form-control"
                id="from_date"
                type="date"
                :max="today"
              />
            </div>
          </div>
          <div class="row my-3 d-flex justify-content-start">
            <div class="col-12 col-lg-4" v-if="0">
              <div class="form-group">
                <label class="form-label" for="game_type">{{ "Game Type" }}</label>
                <select id="game_type" class="form-control" v-model="game_type">
                  <option value="All" selected>
                    {{ translations.reports.All }}
                  </option>
                  <option value="slot-game">
                    {{ translations.header.slot_game ?? "Slot Game" }}
                  </option>
                  <option value="live-casino">
                    {{ translations.header.live_casino }}
                  </option>
                  <option value="table-game">
                    {{ translations.header.table_games ?? "Table Games" }}
                  </option>
                  <option value="scratch-card">
                    {{ translations.header.scratch_cards }}
                  </option>
                  <option value="crash-game">
                    {{ translations.header.crash_games }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label class="form-label" for="type">{{
                  translations.reports.Type
                }}</label>
                <select id="type" class="form-control" v-model="type">
                  <option value="null" selected>
                    {{ translations.reports.All }}
                  </option>
                  <option value="real">
                    {{ translations.reports.Real_Money }}
                  </option>
                  <option value="bonus">
                    {{ translations.reports.Bonus }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form-group">
                <label class="form-label" for="provider">{{
                  translations.reports.Provider
                }}</label>
                <select id="type" class="form-control" v-model="provider">
                  <option value="null" selected>
                    {{ translations.reports.All }}
                  </option>
                  <option
                    v-for="provider in allSkinProvidersForReports"
                    :key="provider?.id"
                    :value="provider?.id"
                  >
                    {{ provider?.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row my-3 d-flex justify-content-center">
            <div class="col-12">
              <base-button
                :caption="translations.reports.Search.toUpperCase()"
                color="orange"
                :disabled="disabledButton"
                @click="search"
              ></base-button>
            </div>
          </div>
        </div>
      </transition>
      <div class="container mt-5">
        <transition name="fade">
          <div v-if="isReportsLoading">
            <base-loading></base-loading>
          </div>

          <div v-else>
            <div v-if="!isFirstLoadCasino && responseLength != 0">
              <casino-reports :casinoList="casinoList"></casino-reports>
            </div>
            <data-table-component
              :casinoList="casinoList"
              :translations="translations"
              :skinName="skinName"
              :isDesktop="isDesktop"
            ></data-table-component>
            <!-- <div v-if="!isFirstLoadCasino && responseLength == 0">
							<div class="row">
								<h3 class="text-center">
									{{ translations.reports.Ooops_search }}
								</h3>
							</div>
						</div> -->
          </div>
        </transition>
      </div>
    </template>
  </base-dashboard-section>
</template>

<script>
import CasinoReports from "./Reports/CasinoReports.vue";
import DataTableComponent from "@/components/elements/DataTableComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CasinoReports,
    DataTableComponent,
  },
  data() {
    return {
      today: null,
      yesterday: null,
      from_date: null,
      to_date: null,
      status: null,
      provider: null,
      type: null,
      game_type: "All",
      responseLength: null,
      dataToPass: [],
    };
  },
  watch: {
    casinoList(value) {
      if (value.data != null) {
        this.responseLength = value.data.length;
      }
    },
  },
  computed: {
    ...mapGetters("casino", ["allSkinProviders", "allSkinProvidersForReports"]),
    ...mapGetters("reports", ["isReportsLoading", "isFirstLoadCasino", "casinoList"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    skinName() {
      return this.$store.getters["skinSettings/skinName"];
    },
    isDesktop() {
      return this.$store.getters["device/isDesktop"];
    },
    disabledButton() {
      return this.from_date > this.to_date ? true : false;
    },
  },
  methods: {
    getDate() {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      let from = new Date();
      from.setDate(from.getDate());
      this.today = new Date().toISOString().slice(0, 10);
      this.yesterday = yesterday.toISOString().slice(0, 10);
      this.to_date = new Date().toISOString().slice(0, 10);
      this.from_date = from.toISOString().slice(0, 10);
    },
    requestCasinoList() {
      const params = {
        from: this.from_date,
        to: this.to_date,
        type: this.type,
        provider: this.provider,
      };
      this.$store.dispatch("reports/getCasinoReports", params);
    },
    search() {
      this.requestCasinoList(this.from_date, this.to_date, this.type, this.provider);
    },
  },
  async created() {
    this.getDate();
    await this.$store.dispatch("casino/callAllSkinProvider");
  },
  mounted() {
    this.search();
  },
};
</script>

<style scoped>
.date-field {
  background: var(--extralight-grey);
  border-radius: 10px;
  transition: var(--short-trans);
  transform: scale(1);
}
</style>
