<template>
  <base-dashboard-section>
    <template #nameSection>
      {{ translations.dashboard_menu.Sowq ?? "SOWQ" }}
    </template>
    <template #default>
      <p class="text-center m-3" style="line-height: 1.2rem; font-weight: 600">
        {{ translations.myprofile.custom_message_sowq ?? "" }}
      </p>

      <div class="container">
        <div
          v-if="
            amlLevel.level == 'low' &&
            !playerSurvey.survey_answers.length &&
            playerSurvey.status != 'required'
          "
        >
          {{
            translations.dashboard_menu.nosowq ??
            "It is not necessary to complete the questionnaire."
          }}
        </div>
        <div v-else>
          <div
            class="row d-flex justify-content-center"
            v-if="
              (playerSurvey.survey_answers &&
                !playerSurvey.survey_answers.length) ||
              playerSurvey.status == 'required'
            "
          >
            <form @submit.prevent id="sendsowq">
              <div class="container">
                <h4>
                  <b>{{ playerSurvey.name }}</b>
                </h4>
                <p>{{ playerSurvey.description }}</p>
              </div>
              <div class="container boxquestions">
                <div
                  class="col-12 p-0 m-0 singleq my-2"
                  v-for="(contents, i) in playerSurvey.survey_contents"
                  :key="contents.id"
                >
                  <div
                    class="form-group"
                    v-if="questions.answers[i] && contents.type == 'select'"
                  >
                    <label class="form-label" :for="'survey_' + contents.id"
                      >{{ contents.question }}
                    </label>
                    <select
                      :data-id="i"
                      v-model="questions.answers[i].answer"
                      @change="changeOption"
                      :required="contents.is_required ? true : false"
                      :id="'survey_' + contents.id"
                      class="form-control"
                    >
                      <option
                        :data-id="option.id"
                        :value="option.value"
                        v-for="option in contents.survey_content_options"
                        :key="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                  <div
                    class="form-group"
                    v-if="questions.answers[i] && contents.type == 'text'"
                  >
                    <label class="form-label" :for="'survey_' + contents.id"
                      >{{ contents.question }}
                    </label>
                    <input
                      :data-id="i"
                      v-model="questions.answers[i].answer"
                      :required="contents.is_required ? true : false"
                      :id="'survey_' + contents.id"
                      class="form-control"
                      type="text"
                    />
                  </div>
                  <div
                    class="form-group"
                    v-if="questions.answers[i] && contents.type == 'number'"
                  >
                    <label class="form-label" :for="'survey_' + contents.id"
                      >{{ contents.question }}
                    </label>
                    <input
                      :data-id="i"
                      v-model="questions.answers[i].answer"
                      :required="contents.is_required ? true : false"
                      :id="'survey_' + contents.id"
                      class="form-control"
                      type="number"
                    />
                  </div>
                  <div
                    class="form-group"
                    v-if="questions.answers[i] && contents.type == 'textarea'"
                  >
                    <label class="form-label" :for="'survey_' + contents.id"
                      >{{ contents.question }}
                    </label>
                    <textarea
                      :data-id="i"
                      v-model="questions.answers[i].answer"
                      :required="contents.is_required ? true : false"
                      :id="'survey_' + contents.id"
                      class="form-control"
                    />
                  </div>
                  <div
                    class="form-group"
                    v-if="questions.answers[i] && contents.type == 'date'"
                  >
                    <label class="form-label" :for="'survey_' + contents.id"
                      >{{ contents.question }}
                    </label>
                    <input
                      :data-id="i"
                      v-model="questions.answers[i].answer"
                      :required="contents.is_required ? true : false"
                      :id="'survey_' + contents.id"
                      class="form-control"
                      type="date"
                    />
                  </div>
                  <div
                    class="form-group"
                    v-if="questions.answers[i] && contents.type == 'checkbox'"
                  >
                    <label class="form-label" :for="'survey_' + contents.id"
                      >{{ contents.question }}
                    </label>
                    <div
                      class="form-group"
                      v-for="options in contents.survey_content_options"
                      :key="options.id"
                    >
                      <input
                        v-model="answersCheckbox[options.id]"
                        :data-contents="contents.id"
                        :data-i="i"
                        :id="'survey_' + contents.id + '_' + options.id"
                        type="checkbox"
                        :class="
                          'form-check-input pr-2 checkbox ' +
                          'check_' +
                          contents.id
                        "
                        :true-value="options.value"
                        :data-value="options.value"
                        @change="answerCheckbox"
                        false-value=""
                      />
                      <label
                        class="form-check-label"
                        :for="'survey_' + contents.id + '_' + options.id"
                      >
                        {{ options.name }}
                      </label>
                    </div>
                  </div>
                  <div
                    class="form-group"
                    v-if="questions.answers[i] && contents.type == 'radio'"
                  >
                    <label class="form-label" :for="'survey_' + contents.id"
                      >{{ contents.question }}
                    </label>
                    <div
                      class="form-group"
                      v-for="options in contents.survey_content_options"
                      :key="options.id"
                    >
                      <input
                        :data-id="i"
                        v-model="questions.answers[i].answer"
                        :id="'survey_' + contents.id + '_' + options.id"
                        type="checkbox"
                        :class="
                          'form-check-input pr-2 checkbox ' +
                          'check_' +
                          contents.id
                        "
                        :true-value="options.value"
                        :data-value="options.value"
                        false-value=""
                      />
                      <label
                        class="form-check-label"
                        :for="'survey_' + contents.id + '_' + options.id"
                      >
                        {{ options.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label">&nbsp;</label>
                <base-button
                  color="orange"
                  :caption="
                    translations?.dashboard_menu?.sowq_submit_btn ?? 'Submit'
                  "
                  nomargin="true"
                  @click="callSurveyAnswer"
                ></base-button>
              </div>
            </form>
          </div>
          <div v-else>
            <!-- è stato gia risposto al questionario-->
            <div class="container">
              <p>
                {{
                  translations.dashboard_menu.answer_sowq ??
                  "You have already answered the SOWQ"
                }}
              </p>
              <h4>
                <b>{{ playerSurvey.name }}</b>
              </h4>
              <p class="mb-0">
                {{
                  translations.dashboard_menu.your_answers ??
                  "these were your answers"
                }}
              </p>
              <ul class="p-0">
                <li
                  v-for="answers in playerSurvey.survey_answers"
                  :key="answers.id"
                >
                  <b>{{ answers.question }} </b> <br />
                  {{ answers.answer }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-dashboard-section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      questions: {
        type: "sowq",
        language: "en",
        survey_id: "",
        answers: [],
      },
      answersCheckbox: [],
    };
  },
  computed: {
    ...mapGetters("player", ["playerInfo", "playerSurvey", "amlLevel"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("api", ["endpoint", "axios", "auth"]),
  },

  methods: {
    async getAmlLevel() {
      this.$store.dispatch("player/getAmlLevel").then(() => {});
    },

    async getSurveyAnswer() {
      this.getAmlLevel();
      this.$store.dispatch("player/getSurvey", this.questions.type).then(() => {
        let responses = [];
        this.questions.survey_id = this.playerSurvey.id;
        for (let x = 0; x < this.playerSurvey.survey_contents.length; x++) {
          let quest = this.playerSurvey.survey_contents[x];

          responses[x] = new Object();
          responses[x].question_id = quest["id"];
          responses[x].language_id = quest["language_id"];
          let defaults = "";
          for (let y = 0; y < quest.survey_content_options.length; y++) {
            let options = quest.survey_content_options[y];

            if (options.is_default) {
              defaults = options.value;

              responses[x].option_id = options.id;
              if (quest.type == "checkbox")
                this.answersCheckbox[options.id] = options.value;

              break;
            }
          }
          responses[x].answer = defaults;
        }
        this.questions.answers = responses;
      });
    },
    changeOption(event) {
      let id = event.target.getAttribute("data-id");
      let opt =
        event.target[event.target.selectedIndex].getAttribute("data-id");
      this.questions.answers[id].option_id = opt;
    },
    answerCheckbox(event) {
      let i = event.target.getAttribute("data-i");
      let contents = event.target.getAttribute("data-contents");
      let cusid = document.getElementsByClassName("check_" + contents);
      let count = 1;
      let val = "";
      for (let y = 0; y < cusid.length; ++y) {
        if (cusid[y].checked == true) {
          val += " " + count + ")" + cusid[y].getAttribute("data-value");
          count++;
        }
      }
      this.questions.answers[i].answer = val;
    },

    callSurveyAnswer() {
      this.$store
        .dispatch("player/surveyAnswers", this.questions)
        .then(() => {});
    },
  },
  created() {
    this.getSurveyAnswer();
  },
};
</script>

<style scoped>
input:disabled:hover {
  cursor: not-allowed;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.date-input {
  padding: 5px 0px;
  background: var(--light-orange);
  border: 2px solid var(--dark-orange);
  border-radius: 10px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}
ul {
  list-style-type: none;
}
</style>
