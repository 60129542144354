<template>
  <div class="container main-container" :class="isLandscape ? 'landscape' : ''">
    <div class="second-container" v-if="!isDesktopCheck">
      <div
        v-if="banner.options.html != null"
        class="container slider-text-title"
        :class="isLandscape ? 'landscape' : ''"
      >
        <h3>
          {{ bannerOptionHtml ?? "" }}
        </h3>
      </div>
      <div
        class="title-subtitle-container"
        :class="isLandscape ? 'landscape' : ''"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 v-html="banner.title"></h1>
            </div>
            <div class="col-12 mt-3">
              <h2 v-html="banner.subtitle"></h2>
            </div>
          </div>
          <div
            v-if="bannerOptionButtonText || bannerOptionButtonText_1"
            class="button-container"
            :class="isLandscape ? 'landscape' : ''"
          >
            <div class="container">
              <div class="row justify-content-center">
                <div v-if="bannerOptionButtonText" class="col-6 m-0 p-0">
                  <a :href="bannerOptionButtonUrl">
                    <base-button
                      :caption="bannerOptionButtonText ?? ''"
                      color="sliderGrey"
                    ></base-button>
                  </a>
                </div>
                <div v-if="bannerOptionButtonText_1" class="col-6 m-0 p-0">
                  <a :href="bannerOptionButtonUrl_1">
                    <base-button
                      :caption="bannerOptionButtonText_1 ?? ''"
                      color="sliderGreen"
                    ></base-button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        v-if="bannerOptionButtonText || bannerOptionButtonText_1"
        class="button-container"
        :class="isLandscape ? 'landscape' : ''"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div v-if="bannerOptionButtonText" class="col-6 m-0 p-0">
              <a :href="bannerOptionButtonUrl">
                <base-button
                  :caption="bannerOptionButtonText ?? ''"
                  color="sliderGrey"
                ></base-button>
              </a>
            </div>
            <div v-if="bannerOptionButtonText_1" class="col-6 m-0 p-0">
              <a :href="bannerOptionButtonUrl_1">
                <base-button
                  :caption="bannerOptionButtonText_1 ?? ''"
                  color="sliderGreen"
                ></base-button>
              </a>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div v-if="!isDesktopCheck"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight,
      isDesktopCheck: true,
    };
  },
  props: ["banner", "isDesktop", "isLandscape"],
  computed: {
    bannerOptionHtml() {
      if (this.isDesktopCheck) {
        return this.banner.options.html;
      } else {
        return this.banner.options.html_mobile;
      }
    },
    bannerOptionButtonText() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_text;
      } else {
        return this.banner.options.button_text_mobile;
      }
    },
    bannerOptionButtonText_1() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_text_1;
      } else {
        return this.banner.options.button_text_mobile_1;
      }
    },
    bannerOptionButtonUrl() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_url;
      } else {
        return this.banner.options.button_url_mobile;
      }
    },
    bannerOptionButtonUrl_1() {
      if (this.isDesktopCheck) {
        return this.banner.options.button_url_1;
      } else {
        return this.banner.options.button_url_mobile_1;
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.deviceWidth = window.innerWidth;
      this.deviceHeight = window.innerHeight;
      this.isDesktopCheck = this.deviceWidth >= 600;
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.main-container {
  top: 5px;
  max-width: 80%;
  left: 0;
  right: 0;
  position: relative;
  max-height: 300px;
}
.main-container.landscape {
  top: 0%;
  max-width: 75%;
  position: absolute;
}
.second-container {
  display: flex;
  min-height: 21.9rem;
  flex-direction: column;
  position: relative;
}
.slider-text-title {
  padding: 10px 5px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--kajot-grey);
  border: 2px solid var(--light-green);
  position: relative;
  margin: 20px 0px;
}
.slider-text-title.landscape {
  padding: 15px 5px;
  position: relative;
}
.title-subtitle-container {
  position: relative;
  margin: 5px 0px;
  width: 100%;
}
.title-subtitle-container.landscape {
  top: 17%;
  position: relative;
}
h1,
h2,
h3 {
  text-align: center;
  color: var(--kajot-grey);
  text-shadow: 0 0 25px rgb(255 255 255 / 40%);
  margin: 0;
}
h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
h2 {
  font-size: 14px;
  font-weight: 500;
}
h3 {
  font-size: 12px;
  font-weight: 600;
}
.button-container {
  z-index: 29;
  top: 65%;
  min-width: 100%;
  position: relative;
  margin-top: 10px;
}
.button-container.landscape {
  top: 65%;
}

@media (max-width: 480px) {
  /* .slider-text-title {
			border: none;
		} */

  h1 {
    line-height: 25px;
  }

  h2 {
    line-height: 0.9;
  }

  .button-container {
    top: 65%;
    position: relative;
    margin-top: 10px;
  }
}
</style>
