<template>
  <base-dashboard-section>
    <template #nameSection>{{ translations.myprofile.Documents }}</template>
    <template #default>
      <p class="text-center m-3" style="line-height: 1.2rem; font-weight: 600">
        {{ translations.myprofile.custom_message_documents ?? "" }}
      </p>
      <transition name="fade">
        <base-loading v-if="isPlayerLoading"></base-loading>
      </transition>
      <transition name="fade">
        <div v-if="!isPlayerLoading" class="container">
          <div class="row d-flex justify-content-center">
            <accordion-container>
              <accordion-element>
                <template #accordionTitle>
                  {{ translations.myprofile.Identification ?? "IDENTIFICATION" }}
                </template>
                <template #accordionContent>
                  <multi-document-form
                    :translations="translations"
                    :playerDocuments="playerDocuments"
                    :IDList="IDList"
                  >
                  </multi-document-form>
                </template>
              </accordion-element>
              <div v-for="(item, index) in playerDocuments" :key="index">
                <accordion-element v-if="!IDList.includes(index)" :i="index">
                  <template #accordionTitle>
                    {{ translations.myprofile[index] ?? index }}
                  </template>
                  <template #accordionContent>
                    <document-form
                      :translations="translations"
                      :i="index"
                      :item="item"
                    ></document-form>
                  </template>
                </accordion-element>
              </div>
            </accordion-container>
          </div>
        </div>
      </transition>
    </template>
  </base-dashboard-section>
</template>

<script>
import { mapGetters } from "vuex";
import AccordionContainer from "@/components/elements/Accordion/AccordionContainer.vue";
import AccordionElement from "@/components/elements/Accordion/AccordionElement.vue";
import DocumentForm from "@/components/elements/Forms/DocumentForm.vue";
import MultiDocumentForm from "@/components/elements/Forms/MultiDocumentForm.vue";

export default {
  components: {
    AccordionContainer,
    AccordionElement,
    DocumentForm,
    MultiDocumentForm,
  },
  computed: {
    ...mapGetters("player", ["playerDocuments", "isPlayerLoading"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
  },
  data() {
    return {
      IDList: ["id_card", "passport", "driver_license"],
    };
  },
  async created() {
    await this.$store.dispatch("player/callUserDocuments");
  },
  mounted() {
    // const lang = localStorage.getItem("language") || "en";
    // const titles = {
    //   en: "Kajot Online Casino | My identity",
    //   sk: "Kajot Online Casino | Moja identita",
    //   cs: "Kajot Online Casino | Moje totožnost",
    //   de: "Kajot Online Casino | Meine Identität",
    //   hu: "Kajot Online Casino | Az én identitásom",
    // };
    // document.title = titles[lang] || "Kajot Online Casino | My identity";
    document.title =
      this.translations?.body?.playerDocPageTitle ?? "Kajot Online Casino | My identity";
  },
};
</script>
