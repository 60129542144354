export default {
   namespaced: true,
   state() {
      return {
         path: {
            messages: 'me/messages'
         },
         resetField: false,
         messages: [],
         recipients: []
      };
   },
   getters: {
      endpoint(_, _1, _2, rootGetters) {
         return rootGetters['api/endpoint'];
      },
      axios(_, _1, _2, rootGetters) {
         return rootGetters['api/axios'];
      },
      auth(_, _1, _2, rootGetters) {
         return rootGetters['api/auth'];
      },
      token(_, _1, _2, rootGetters) {
         return rootGetters['player/token'];
      },
      tokenAuth(_, _1, _2, rootGetters) {
         return rootGetters['player/tokenAuth'];
      },
      translations(_, _1, _2, rootGetters) {
         return rootGetters['translations/translations'];
      },
      recipients(state) {
         return state.recipients;
      },
      messages(state) {
         return state.messages;
      },
      resetField(state) {
         return state.resetField;
      }
   },
   mutations: {
      resetMessage(state, status) {
         state.resetField = status;
      },
      setMessages(state, payload) {
         state.messages = payload.data.data;
      },
      setRecipients(state, payload) {
         state.recipients = payload.data.data;
      },
   },
   actions: {
      async getRecipients({ state, getters, commit }) {
         const path = state.path.messages + '/message-groups';
         const responseData = await getters.axios.get(getters.endpoint + path, getters.tokenAuth).then(response => response).catch(err => err);
         commit('setRecipients', responseData);
      },
      async getMessages({ state, getters,commit }, parameters) {
         const path = state.path.messages;
         const responseData = await getters.axios.get(getters.endpoint + path + parameters, getters.tokenAuth).then(response => response).catch(response => response);
         commit('setMessages',responseData);
      },
      async sendMessage({ state, getters, commit }, payload) {
         const path = state.path.messages;
         const message = {
            "data": {
               'message': {
                  "_token": getters.token,
                  "subject": payload.subject,
                  "body": payload.body,
                  "messenger-groups": payload.recipient
               }
            },
         };
         const headers = getters.tokenAuth;
         const responseData = await getters.axios.post(getters.endpoint + path, message, headers).then(response => response).catch(err => err);
         if (responseData.status == 200) {
            const message = getters.translations.my_inbox.message_sent;
            const status = 'success';
            commit('alert/top_right_notification', { message, status }, { root: true });
            commit('resetMessage', true);
         } else {
            const message = 'ERROR';
            const status = 'error';
            commit('alert/top_right_notification', { message, status }, { root: true });
         }
      }
   }
}; 