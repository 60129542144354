<template>
  <div class="d-flex w-100 justify-content-center" style="min-height: 50vh">
    <div class="update-psw-imported-player-page">
      <h2 class="text-center">
        {{ translations?.body?.updatePswPage ?? "Update Password" }}
      </h2>
      <hr />
      <form @submit.prevent="updatePassword" class="password-form">
        <div class="form-group">
          <label for="currentPassword">
            {{ translations?.body?.actualPsw ?? "Actual Password" }}:
          </label>
          <input
            type="password"
            id="currentPassword"
            v-model="currentPassword"
            required
          />
        </div>

        <div class="form-group">
          <label for="newPassword">
            {{ translations?.body?.newPsw ?? "New Password" }}:
          </label>
          <input
            type="password"
            id="newPassword"
            v-model="newPassword"
            required
            :class="{
              'is-invalid': !isPasswordValid(newPassword) && newPassword.length > 0,
            }"
          />
          <div
            v-if="!isPasswordValid(newPassword) && newPassword.length > 0"
            class="invalid-feedback"
          >
            <small>
              {{
                translations?.body?.psw_must_respect_regex ??
                "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
              }}</small
            >
          </div>
        </div>

        <div class="form-group">
          <label for="confirmNewPassword">
            {{ translations?.body?.confirm_newPsw ?? "Confirm New Password" }}:
          </label>
          <input
            type="password"
            id="confirmNewPassword"
            v-model="confirmNewPassword"
            required
            :class="{
              'is-invalid': newPasswordErroConfirm,
            }"
          />
          <div
            v-if="confirmNewPassword.length > 1 && confirmNewPassword !== newPassword"
            class="invalid-feedback"
          >
            <small>
              {{ newPasswordErroConfirm }}
            </small>
          </div>
        </div>
        <div class="btn-submit">
          <button type="submit" :class="!isFormValid ? 'disabled' : ''">
            {{ translations?.body?.updatePasswordBtn ?? "Update Password" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      lang: localStorage.getItem("language") || "en",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      isFormValid: false,
      newPasswordErroConfirm: "",
    };
  },
  computed: {
    ...mapGetters("player", ["isPlayerLoading", "playerInfo"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
  },
  methods: {
    updatePassword() {
      if (this.isFormValid) {
        let body = { password: this.newPassword, is_crypt: true };
        let playerId = this.playerInfo.user_id;
        this.$store
          .dispatch("registration/updatePlayer", { body, playerId })
          .then(() => {
            window.location.href = "/";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    isPasswordValid(password) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return passwordRegex.test(password);
    },
    validateNewPassword() {
      if (!this.isPasswordValid(this.newPassword)) {
        this.newPasswordError =
          this.translations?.body?.psw_must_respect_regex ??
          "Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.";
      } else {
        this.newPasswordError = "";
      }
    },
    validateConfirmPassword() {
      this.newPasswordErroConfirm =
        this.confirmNewPassword.length > 1 && this.confirmNewPassword !== this.newPassword
          ? this.translations?.body?.psw_doesnt_match ?? "Password doesn't match"
          : "";
    },
    validateForm() {
      this.isFormValid =
        this.isPasswordValid(this.newPassword) &&
        this.confirmNewPassword === this.newPassword;
      this.validateNewPassword();
    },
  },
  watch: {
    currentPassword() {
      this.validateForm();
    },
    newPassword() {
      this.validateForm();
    },
    confirmNewPassword() {
      this.validateConfirmPassword();
      this.validateForm();
    },
  },
  created() {
    this.$router.push({ params: { lang: this.lang } });
  },
};
</script>

<style scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
  box-shadow: 0px 1px 10px 2px #dc3545 !important;
}

.invalid-feedback {
  color: #dc3545;
  font-size: 80%;
  margin-top: 5px;
}
.update-psw-imported-player-page {
  padding: 80px 15px 0px 15px;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.password-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

input {
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  outline: none;
  transition: 0.3s all;
}

input:focus {
  box-shadow: 0px 1px 10px 2px var(--dark-orange);
}

button {
  background-color: var(--dark-orange);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  transition: 0.4s all;
  width: 100%;
}

button:hover {
  box-shadow: 0px 1px 10px 2px var(--dark-orange);
}

.btn-submit {
  padding-top: 30px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.disabled {
  opacity: 0.7;
  transition: 0.3s all;
  cursor: not-allowed !important;
}
</style>
