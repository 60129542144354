<template>
	<div class="container my-2">
		<div class="row d-flex justify-content-center">
			<div class="col-12 col-md-6">
				<VueRecaptcha
					:sitekey="siteKey"
					:load-recaptcha-script="true"
					@verify="handleSuccess()"
					@error="handleError()"
					@expired="handleError()"
				></VueRecaptcha>
			</div>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { VueRecaptcha } from 'vue-recaptcha';

	export default defineComponent({
		name: 'ReCaptcha',
		components: {
			VueRecaptcha,
		},
		props: ['siteKey'],
		methods: { 
			handleError() {
				this.$emit('clicked',false)
		   },
		   handleSuccess() {
				this.$emit('clicked',true)
		   },
		},
		emits: ['clicked'],
	});
</script>
