<template>
  <section>
    <base-loading v-if="(isTranslationLoading || isCheckingPendingWithdraw) && !isMethodLoaded"></base-loading>
    <base-dashboard-section v-else>
      <template #nameSection>{{ translations.buttons.Withdraw }}</template>
      <p class="text-center m-3" style="line-height: 1.2rem; font-weight: 600">
        {{ translations.myprofile.custom_message_withdraw ?? "" }}
      </p>
      <div v-if="!!pendingTransaction">
        <div class="container mb-5">
          <div class="row justify-content-center">
            <div class="col-12 my-5 text-error text-center">
              {{ translations.account_balance.There_is_a_pending_transaction }}
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-11 col-md-8">
              <div class="row mb-5 justify-content-around align-items-center pending-section">
                <div class="col-11 col-md-4">
                  <div class="row my-2">
                    <div class="text-center"><b>ID:</b> {{ pendingTransaction.id }}</div>
                  </div>
                  <div class="row my-2">
                    <div class="text-center">
                      <b>{{ translations.reports.Status }}:</b>
                      {{ translations.account_balance.Pending }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="text-center">
                      <b>{{ translations.reports.Type }}:</b>
                      {{ translations.account_balance.Withdraw }}
                    </div>
                  </div>
                  <div class="row my-2">
                    <div class="text-center">
                      <b>{{ translations.account_balance.Amount }}:</b>
                      {{ pendingTransaction.currency.symbol
                      }}{{ +pendingTransaction.amount }}
                    </div>
                  </div>
                </div>
                <div class="col-11 col-md-4">
                  <div class="row">
                    <base-button :caption="translations.buttons.Delete.toUpperCase()" color="orange" nomargin="true"
                      @click="
                        deletePendingTransaction(
                          pendingTransaction.payment_method_id,
                          pendingTransaction.id
                        )
                        "></base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!playerInfo.is_verified">
        <div class="container mb-5">
          <div class="row justify-content-center">
            <div class="col-12 my-5 text-error text-center">
              {{ translations.myprofile.Account_not_yet_verified }}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="sowqMessage">
        <div class="container mb-5">
          <div class="row justify-content-center">
            <div class="col-12 my-5 text-error text-center">
              You need to answer a questionnaire in order to proceed with the withdrawal.
              <router-link :to="`/${language_selected}/sowq`" @click="scrollToBottom">
                {{ translations.dashboard_menu.click_here }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <transition name="form">
          <div class="container" v-if="!showMessage">
            <div class="row d-flex justify-content-center">
              <form @submit.prevent>
                <div class="form-group row mt-4 mb-5">
                  <div class="col-12 col-md-6 mt-3">
                    <label class="form-label" for="amount">{{
                      translations.account_balance.Amount
                    }}</label>
                    <input id="amount" type="number" class="form-control" v-model="amount"
                      :placeholder="translations.account_balance.Insert_amount" />
                  </div>
                  <div class="col-12 col-md-6 mt-3">
                    <label class="form-label">{{
                      translations.account_balance.Account
                    }}</label>
                    <select class="form-control" name="account_id" id="account_id" v-model="account_id">
                      <option value="null" disabled selected>
                        {{ translations.account_balance.Choose_account }}
                      </option>
                      <option v-for="method in withdrawPaymentMethods" :key="method.id" :value="method.id">
                        {{ method.name }}
                      </option>
                    </select>
                  </div>
                  <!--qui mostrare-->
                  <transition name="error-message">
                    <div v-if="amount && account_id">
                      <transition name="fade">
                        <div v-if="account_id != null" class="col-12 my-3">
                          <div class="row justify-content-around align-items-center limits-suggest">
                            <!-- <div class="col-12 col-md-2">
                              <div class="text-center">
                                <i>{{ methodName }}</i>
                              </div>
                            </div> -->
                            <div class="col-12 col-md-4">
                              <div class="text-center">
                                <b>
                                  {{
                                    translations.account_balance
                                      .minWithdrawable_for_method ?? "Min withdrawable"
                                  }}:</b>
                                {{ playerInfo?.currency?.symbol ?? "" }}
                                {{ minWithdrawable_for_method }}
                              </div>
                            </div>
                            <div class="col-12 col-md-4">
                              <div class="text-center">
                                <b>
                                  {{
                                    translations.account_balance.Withdrawable_Balance
                                  }}:</b>
                                {{
                                  playerInfo?.currency?.symbol ?? "Minimum withdrawable"
                                }}
                                {{ maxWithdrawable }}
                              </div>
                            </div>
                            <div class="col-6 col-md-4">
                              <div class="text-center">
                                <b>
                                  {{
                                    translations.account_balance.withdrawal_limit ??
                                    "Withdrawal limit"
                                  }}:</b>
                                {{ playerInfo?.currency?.symbol ?? "" }}
                                {{ maxWithdrawable_for_method }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition>
                      <p v-if="
                        errorAmount &&
                        amount > maxWithdrawable &&
                        amount <= maxWithdrawable_for_method
                      " class="mt-2 warning">
                        <!-- {{ errorMessage_amount_withdrawal_to_high }} -->
                        {{ errorMessage_amount_invalid }}
                      </p>
                      <p v-else-if="errorAmount && amount > maxWithdrawable_for_method" class="mt-2 warning">
                        <!-- {{ errorMessage_amount_withdrawal_to_high_than_limits }} -->
                        {{ errorMessage_amount_invalid }}
                      </p>
                      <p v-else-if="errorAmount && amount < minWithdrawable_for_method" class="mt-2 warning">
                        <!-- {{
                          errorMessage_amount_withdrawal_to_less_than_minimum
                        }} -->
                        {{ errorMessage_amount_invalid }}
                      </p>
                    </div>
                  </transition>
                </div>
                <div class="form-group row mt-2 mb-2" v-if="methodName && methodName.toLowerCase().includes('sepa')">
                  <div class="col-12 mt-3">
                    <label class="form-label" for="iban">{{
                      translations.account_balance.iban_field ?? "IBAN"
                    }}</label>
                    <input id="iban" type="text" class="form-control" v-model="iban" @input="updateIbanValidity()"
                      :placeholder="translations.account_balance.insert_your_iban ??
                        'Insert your IBAN'
                        " />
                  </div>
                  <small v-if="isIbanIncorrect && iban.length !== 0" class="error-message-iban">{{
                    translations?.account_balance?.error_iban_field ??
                    "IBAN must contain between 5 and 34 characters and special charactersare not allowed."
                  }}</small>
                </div>
                <div class="form-group row mt-4 mb-5">
                  <base-button @click="withdrawCall" color="orange"
                    :caption="translations.buttons.Withdraw.toUpperCase()"
                    :disabled="isWithdrawButtonDisabled"></base-button>
                </div>
              </form>
            </div>
          </div>
        </transition>

        <transition name="form">
          <div class="container" v-if="showMessage">
            <transition name="form">
              <div>
                <div class="error-message" v-if="!!withdrawErrorMessage1">
                  {{ withdrawErrorMessage1 }}
                </div>
              </div>
            </transition>
            <transition name="form">
              <div class="error-message" v-if="!!withdrawErrorMessage2">
                {{ withdrawErrorMessage2 }}
              </div>
            </transition>
            <transition name="form">
              <div>
                <div id="formInFrame" class="row mb-5 form" v-html="withdrawForm" target="_PARENT"></div>
              </div>
            </transition>
          </div>
        </transition>
      </div>
    </base-dashboard-section>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      language_selected: localStorage.getItem("language"),
      amount: null,
      isAmountSet: false,
      account_id: null,
      isAccountSet: false,
      showMessage: false,
      sowqMessage: false,
      withdrawForm: null,
      withdrawErrorMessage1: null,
      withdrawErrorMessage2: null,
      isLoadingWithdraw: false,
      selectedMethod_Name: null,
      isMethodLoaded: false,
      iban: null,
      isIbanIncorrect: false,
    };
  },
  computed: {
    methodName() {
      const name = () => {
        let selected = this.account_id;
        let selectedMethod = this.withdrawPaymentMethods?.find(
          (method) => method.id == selected
        );
        if (selected != null) {
          if (!selectedMethod.name.toLowerCase().includes("sepa")) {
            this.iban = null;
          }
          return selectedMethod.name;
        }
      };
      return name();
    },
    pendingTransaction() {
      return this.pendingWithdraw.length != 0 ? this.pendingWithdraw[0] : null;
    },

    isWithdrawButtonDisabled() {
      return (
        !this.isAmountSet ||
        !this.isAccountSet ||
        this.errorAmount ||
        this.isLoadingWithdraw ||
        (this.methodName &&
          this.methodName.toLowerCase().includes("sepa") &&
          !this.isIbanValid())
      );
    },
    maxWithdrawable() {
      const maximum = () => {
        let playerBalanceAvailable = this.playerInfo?.balance?.available.toFixed(2);
        let selected = this.account_id;
        if (selected != null) {
          return playerBalanceAvailable;
        }
      };
      return maximum();
    },
    maxWithdrawable_for_method() {
      const maximum_for_method = () => {
        let selected = this.account_id;

        const selectedMethod = this.withdrawPaymentMethods?.find(
          (method) => method.id === selected
        );
        return selectedMethod?.withdrawal?.maximum || null;
      };
      return maximum_for_method();
    },
    minWithdrawable_for_method() {
      const minimum_for_method = () => {
        let selected = this.account_id;

        const selectedMethod = this.withdrawPaymentMethods?.find(
          (method) => method.id === selected
        );
        return selectedMethod?.withdrawal?.minimum || null;
      };
      return minimum_for_method();
    },
    errorAmount() {
      if (
        this.amount <= this.maxWithdrawable &&
        this.amount <= this.maxWithdrawable_for_method &&
        this.amount >= this.minWithdrawable_for_method
      ) {
        return false;
      } else if (
        this.amount >= this.maxWithdrawable ||
        (this.amount >= this.max_w && this.amount <= this.min_w)
      ) {
        return true;
      } else {
        return true;
      }
    },
    errorMessage_amount_withdrawal_to_high() {
      return (
        this.translations.account_balance.Amount_too_high +
        " " +
        this.playerInfo?.currency?.symbol +
        this.maxWithdrawable
      );
    },
    errorMessage_amount_withdrawal_to_high_than_limits() {
      return (
        this.translations.account_balance
          .errorMessage_amount_withdrawal_to_high_than_limits ??
        "The maximum limit of your withdrawal method is" +
        " " +
        this.playerInfo?.currency?.symbol +
        this.maxWithdrawable_for_method
      );
    },
    errorMessage_amount_withdrawal_to_less_than_minimum() {
      return (
        this.translations.account_balance
          .errorMessage_amount_withdrawal_to_less_than_minimum ??
        "The minimum you can withdraw allowed by your withdrawal method is" +
        " " +
        this.playerInfo?.currency?.symbol +
        this.minWithdrawable_for_method
      );
    },
    errorMessage_amount_invalid() {
      return (
        this.translations.account_balance.errorMessage_amount_invalid ??
        "Invalid amount chosen"
      );
    },
    ...mapGetters("device", ["isMobile"]),
    ...mapGetters("player", ["playerInfo", "balance", "playerSurvey"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("payments", [
      "withdrawResponse",
      "withdrawalPaymentMethods",
      "withdrawPaymentMethods",
    ]),
    ...mapGetters("reports", ["isCheckingPendingWithdraw", "pendingWithdraw"]),
  },
  watch: {
    amount(value) {
      if (value >= 1) {
        this.isAmountSet = true;
      } else {
        this.isAmountSet = false;
      }
    },
    account_id(value) {
      if (value != null) {
        this.isAccountSet = true;
      } else {
        this.isAccountSet = false;
      }
    },
    withdrawResponse(value) {
      if (Object.prototype.hasOwnProperty.call(value, "message")) {
        this.withdrawErrorMessage1 = value.message;
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
        }, 4000);
        this.isLoadingWithdraw = false;
      } else if (Object.prototype.hasOwnProperty.call(value, "amount")) {
        let amount = value.amount[0];
        let message = amount.charAt(0).toUpperCase() + amount.slice(1);
        this.withdrawErrorMessage2 = message;
        this.showMessage = true;
        setTimeout(() => {
          this.showMessage = false;
        }, 4000);
        this.isLoadingWithdraw = false;
      } else {
        // this.withdrawForm = value;
        // this.showMessage = true;

        if (value.includes("<form")) {
          document.body.innerHTML = value;
          let apco = document.getElementById("submitapco");
          if (apco)
            setTimeout(function () {
              apco.submit();
            }, 2000);
        } else if (value.includes("<iframe")) {
          let startFromSrc = value.slice(value.search("src")).slice(5);
          let src = startFromSrc.slice(0, startFromSrc.search('"'));
          window.open(src, "_blank");
        } else {
          location.reload();
          this.withdrawForm = value;
        }
        this.showMessage = true;
      }
    },
  },
  methods: {
    withdrawCall() {
      const cookieName = "withdrawCall";
      const cookieExists = VueCookies.get(cookieName);

      if (cookieExists) {
        Swal.fire({
          icon: 'warning',
          text: this.translations.dashboard_menu.next_withdrawal_attempts_alert ??
            `The withdrawal requests that can be made are one every 10 minutes`,
          confirmButtonColor: "rgb(219, 81, 18)",
        });
        return;
      }

      this.isLoadingWithdraw = true;
      this.withdrawForm = null;
      this.withdrawErrorMessage1 = null;
      this.withdrawErrorMessage2 = null;
      let fullUrl = location.protocol + "//" + location.host;
      let body = {
        amount: +this.amount,
        account_id: this.account_id,
        cancel_url: "/withdraw",
        redirect_success: fullUrl + "/transactions/{transaction_id}",
        force_pending: 1,
        accountNumber:
          this.methodName && this.methodName.toLowerCase().includes("sepa")
            ? this.iban
            : "",
      };

      let exp = 600;
      VueCookies.set(cookieName, true, exp);
      
      this.$store.dispatch("payments/makeWithdraw", body).then(() => {
       
      }).catch((error) => {
        this.isLoadingWithdraw = false;
        console.error("Errore durante la chiamata di prelievo:", error);
      });
    },
    checkPendingWithdraw() {
      let start = new Date("1970-01-01").toISOString().slice(0, 10);
      let end = new Date().toISOString().slice(0, 10);
      const from = "?date_from=" + start + " 00:00:00";
      const to = "&date_to=" + end + " 23:59:59";
      let type = "&type_id=2";
      let status = "&status=pending";
      let path = from + to + type + status;
      this.$store.dispatch("reports/checkPendingWithdraw", path);
    },
    deletePendingTransaction(provider, id) {
      const body = {
        payload: {
          provider: provider,
          transaction: id,
        },
        dispatch: "reports/deleteTransaction",
      };
      const message = {
        title: this.translations.account_balance.Deleting_transaction,
        text: this.translations.account_balance.Are_you_sure_transaction,
      };
      this.$store.dispatch("alert/alertConfirmOrDeny", { message, body });
    },
    isIbanValid() {
      if (!this.iban) {
        return false;
      }

      const cleanedIban = this.iban.replace(/\s/g, "");
      if (cleanedIban.length < 5 || cleanedIban.length > 34) {
        return false;
      }

      if (!/^[a-zA-Z0-9]+$/.test(cleanedIban)) {
        return false;
      }

      return true;
    },
    updateIbanValidity() {
      this.isIbanIncorrect = !this.isIbanValid();
    },
  },
  async created() {
    this.$store.dispatch("payments/callPaymentMethods").then(() => {
      this.$store
        .dispatch("payments/getWithdrawPaymentMethods")
        .then(() => {
          this.isMethodLoaded = true;
        })
        .catch((error) => {
          console.error("Error during getWithdrawPaymentMethods:", error);
        });
    });

    this.$store.dispatch("player/getSurvey", "sowq").then(() => {
      if (
        this.playerSurvey.is_required == 1 &&
        this.playerSurvey.survey_answers != null &&
        this.playerSurvey.survey_answers.length == 0
      )
        this.sowqMessage = true;
    });
    this.checkPendingWithdraw();
  },
  mounted() {
    // const lang = localStorage.getItem("language") || "en";
    // const titles = {
    //   en: "Kajot Online Casino | Withdrawal",
    //   sk: "Kajot Online Casino | Vyplatiť",
    //   cs: "Kajot Online Casino | Vyplatit",
    //   de: "Kajot Online Casino | Auszahlen",
    //   hu: "Kajot Online Casino | Visszavonás",
    // };
    // document.title = titles[lang] || "Kajot Online Casino | Withdrawal";
    document.title =
      this.translations?.body?.withdrwalPageTitle ?? "Kajot Online Casino | Withdrawal";
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.error-message {
  text-align: center;
  color: var(--dark-red);
  font-weight: 700;
}

.error-message-iban {
  text-align: left;
  color: var(--dark-red);
  font-weight: 600;
  font-size: 0.65rem;
  margin: 5px 0px 0px 0px;
}

@media (min-width: 1px) {
  #formInFrame>>>.col-md-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

#formInFrame>>>button[type="submit"] {
  width: 100%;
}

.pending-section {
  border: 2px solid var(--light-grey);
  box-shadow: var(--light-shadow);
  padding: 10px 20px;
  border-radius: 10px;
}

.limits-suggest {
  padding: 15px 5px;
  box-shadow: var(--light-shadow);
  border: 1px solid var(--light-orange);
  border-radius: 10px;
}
</style>
<!--
// errorAmount() { // if (this.amount > this.maxWithdrawable) { // return true;
// } else if (this.amount <= this.maxWithdrawable) { // return false; // } else
{ // return false; // } // },
    // methodName() {
    //   const name = () => {
    //     let selected = this.account_id;
    //     let selectedMethod = this.withdrawPaymentMethods.find(
    //       (method) => method.id == selected
    //     );
    //     if (selected != null) {
    //       return selectedMethod.name;
    //     }
    //   };
    //   return name();
    // },
      // isWithdrawButtonDisabled() {
    //   return (
    //     !this.isAmountSet ||
    //     !this.isAccountSet ||
    //     this.errorAmount ||
    //     this.isLoadingWithdraw
    //   );
    // },-->
