import moment from 'moment';
import skinSettingsProd from '/public/skinSettings/production.json'
import skinSettingsStage from '/public/skinSettings/stage.json'
import countriesJson from '/public/countries.json'
export default {
	namespaced: true,
	state() {
		return {
			path: {
				settings: 'cms/skin-settings',
				promotions: 'campaigns?all=1&filters[is_public]=',
				page: 'cms/pages/',
				notification: (id) => {
					return `notifications/players/${id}/custom-message`;
				},
				maintenance: 'cms/skin-in-maintenance'
			},
			countries: [],
			countriesAll: [],
			currencies: [],
			campaigns: [],
			page: {},
			googleAnalyticsCode: null,
			googleCaptcha: {},
			id: null,
			layoutSettings: null,
			licenseId: null,
			media: null,
			name: null,
			refId: null,
			revenueCalculation: null,
			informations: {},
			days: [],
			months: [],
			years: [],
			maintenance: false
		};
	},
	mutations: {
		createDates(state) {
			const maximumAge = 115;
			for (let day = 1; day < 32; day++) {
				let rightDay = '0' + day;
				state.days.push({ value: rightDay.slice(-2), number: day });
			}
			for (let month = 0; month < 12; month++) {
				let rightMonth = '0' + (month + 1);
				state.months.push({
					value: rightMonth.slice(-2),
					name: moment().month(month).format('MMMM'),
				});
			}
			for (
				let year = moment().subtract(maximumAge, 'years').format('YYYY');
				year < moment().subtract(17, 'years').format('YYYY');
				year++
			) {
				state.years.unshift({ value: year, number: year });
			}
		},
		setCountriesAll(state, payload) {
			state.countriesAll = payload;
		},
		// setSkinSettings(state, payload) {
		// 	state.countries = payload.countries;
		// 	console.log(state.countries)
		// 	state.currencies = payload.currencies;
		// 	console.log(state.currencies)
		// 	state.googleAnalyticsCode = payload.google_analytics_code;
		// 	state.googleCaptcha = payload.google_captcha;
		// 	state.id = payload.id;
		// 	state.layoutSettings = payload.layout_settings;
		// 	state.licenseId = payload.license_id;
		// 	state.media = payload.media;
		// 	state.name = payload.name;
		// 	state.refId = payload.ref_id;
		// 	state.revenueCalculation = payload.revenue_calculation;
		// 	state.informations = payload.ui_options;
		// },
		setSkinSettings(state, payload) {
			state.countries = payload.countries;
			state.currencies = payload.currencies;
			state.googleAnalyticsCode = payload.google_analytics_code;
			state.googleCaptcha = payload.google_captcha;
			state.id = payload.id;
			state.layoutSettings = payload.layout_settings;
			state.licenseId = payload.licensee_id; // Correggi il nome della chiave
			state.media = payload.media;
			state.name = payload.name;
			state.refId = payload.ref_id;
			state.revenueCalculation = payload.revenue_calculation;
			state.informations = payload.ui_options;
		},
		callCampaigns(state, payload) {
			// console.log('No filtered:');
			state.campaigns = payload
			// console.log(state.campaigns);
			// Filter campaigns based on categories with an empty array
			//state.campaigns = payload.filter(campaign => campaign.categories.length === 0);
		},
		callPage(state, payload) {
			state.page = payload;
		},
		callMaintenance(state, payload) {
			state.maintenance = payload;
		},
	},
	actions: {
		async callSkinSettings({ commit }) {
			//controllo variabile env per vedere quale skin id usare tra prod o stage

			let mode = process.env.VUE_APP_ENVIRONMENT;
			let settings = skinSettingsStage;

			if (mode === 'production')
				settings = skinSettingsProd;

			const responseData = settings.data;

			commit('setSkinSettings', responseData);
		},
		/*
		 async callSkinSettings({ state, getters, commit }) {
				let config = {
					path: state.path.settings,
				};
				let language = '';
				if (localStorage.getItem('language'))
					language = '?language=' + localStorage.getItem('language');

				const responseData = await getters.axios
					.get(
						getters.endpoint + config.path + language,
						getters.auth
					)
					.then((response) => {
						return response.data.data;
					})
					.catch((err) => {
						console.log(err);
						return;
					});
				commit('setSkinSettings', responseData);
		  },
		 */
		async sendNotification({ state, getters }, payload) {
			const body = {
				subject: payload.subjectNotify,
				body: payload.bodyNotify,
				type: payload.type
			};

			await getters.axios
				.post(getters.endpoint + state.path.notification(payload.id), body, getters.auth)
				.then((response) => response)
				.catch((err) => err);

		},
		async callCountriesAll({ state, getters, commit }) {
			console.log(getters);
			console.log(state);
			commit('setCountriesAll', countriesJson.data);
		},
		async callCampaigns({ state, getters, commit }, payload) {
			let country = payload.country;
			let currency = payload.currency;
			let language = getters.language ?? 'en';

			let config = {
				path: state.path.promotions,
				action_type: '1',
				actions: '&filters[actions][0]=2&filters[actions][1]=3&filters[actions][2]=4',
				currency_id: '&filters[language]=' + language + '&filters[currency_id]=' + currency,
				country_id: '&filters[countries][0]=' + country,

			};
			const responseData = await getters.axios
				.get(
					getters.endpoint + config.path + config.action_type + config.currency_id + config.country_id + config.actions,
					getters.auth,
					country
				)
				.then((response) => response.data)
				.catch((err) => err);

			commit('callCampaigns', responseData);
		},
		async callPage({ state, getters, commit }, slug) {
			let config = {
				path: state.path.page + slug,
			};

			const responseData = await getters.axios
				.get(getters.endpoint + config.path, getters.auth)
				.then((response) => {
					return response.data.data;
				})
				.catch((err) => {
					return err;
				});

			commit('callPage', responseData);
		},
		async callSkinInMaintenance({ state, getters, commit }) {
			let config = {
				path: state.path.maintenance
			};

			const responseData = await getters.axios
				.post(getters.endpoint + config.path, {}, getters.auth)
				.then((response) => response.data)
				.catch((err) => err);

			commit('callMaintenance', responseData.in_maintenance);
		},
	},
	getters: {
		endpoint(_, _1, _2, rootGetters) {
			return rootGetters['api/endpoint'];
		},
		axios(_, _1, _2, rootGetters) {
			return rootGetters['api/axios'];
		},
		auth(_, _1, _2, rootGetters) {
			return rootGetters['api/auth'];
		},
		language(_, _1, _2, rootGetters) {
			return rootGetters['translations/language_selected'];
		},
		countries(state) {
			return state.countries;
		},
		countriesAll(state) {
			return state.countriesAll;
		},
		currencies(state) {
			return state.currencies;
		},
		campaigns(state) {
			return state.campaigns;
		},
		googleAnalyticsCode(state) {
			return state.googleAnalyticsCode;
		},
		googleCaptcha(state) {
			return state.googleCaptcha;
		},
		skinId(state) {
			return state.id;
		},
		page(state) {
			return state.page;
		},
		layoutSettings(state) {
			return state.layoutSettings;
		},
		licenseId(state) {
			return state.licenseId;
		},
		media(state) {
			return state.media;
		},
		skinName(state) {
			return state.name;
		},
		refId(state) {
			return state.refId;
		},
		revenueCalculation(state) {
			return state.revenueCalculation;
		},
		informations(state) {
			return state.informations;
		},
		days(state) {
			return state.days;
		},
		months(state) {
			return state.months;
		},
		years(state) {
			return state.years;
		},
		maintenance(state) {
			return state.maintenance;
		}
	},
};
