<template>
  <div class="home">
    <transition name="fade">
      <div v-if="showAllPromotions">
        <div>
          <carousel-full :banners="promotion_banners"></carousel-full>
        </div>
        <transition name="fade">
          <base-loading v-if="isPromotionsLoading"></base-loading>
        </transition>
        <transition name="fade">
          <div
            v-if="!isPromotionsLoading"
            class="header container my-5 pb-5 row m-auto content"
          >
            <promotion-card :promotions="promotionsList"></promotion-card>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="showPromoDetail">
        <promotion-detail
          v-if="!isPromoIdLoading"
          :promotion="promotion"
          :lang="lang"
        ></promotion-detail>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromotionCard from "@/components/global/ui/CardPromotion.vue";
import PromotionDetail from "@/components/global/ui/PromotionDetail.vue";
import CarouselFull from "@/components/elements/CarouselFull.vue";

export default {
  data() {
    return {
      lang: "",
    };
  },
  components: {
    CarouselFull,
    PromotionCard,
    PromotionDetail,
  },
  computed: {
    ...mapGetters("skinPromotions", [
      "promotionsList",
      "promotion",
      "isPromotionsLoading",
      "isPromoIdLoading",
    ]),
    ...mapGetters("skinBanners", [
      "promotion_banners",
      "isLoadingBanners",
      "default_banners",
    ]),
    showAllPromotions() {
      return (
        this.$route.params.promotionId === "" ||
        this.$route.params.promotionId === undefined
      );
    },
    showPromoDetail() {
      return (
        this.$route.params.promotionId !== undefined &&
        this.$route.params.promotionId !== ""
      );
    },
  },
  watch: {
    "$route.params.promotionId": {
      handler: function (value) {
        if (value === undefined) {
          this.$store.dispatch("skinPromotions/callSkinPromotions");
        } else {
          this.$store.dispatch("skinPromotions/callSkinPromotions", value);
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("skinBanners/callBanners", "promotions");
    let language = localStorage.getItem("language");
    this.$router.push({ params: { lang: language } });
  },
  mounted() {
    // const titles = {
    //   en: "Kajot Online Casino | Promotions",
    //   sk: "Kajot Online Casino | Akcie",
    //   cs: "Kajot Online Casino | Akce",
    //   de: "Kajot Online Casino | Aktionen",
    //   hu: "Kajot Online Casino | Promóciók",
    // };
    // this.lang = this.$route.params.lang || "en"; // Imposta la lingua corrente dal parametro del percorso
    // document.title = titles[this.lang] || "Kajot Online Casino | Promotions";
    document.title =
      this.translations?.body?.promotionPageTitle ?? "Kajot Online Casino | Promotions";
  },
  beforeUnmount() {
    this.$store.dispatch("skinPromotions/clearPromotion");
  },
};
</script>

<style scoped>
.content {
  min-height: 65vh;
}
</style>

<!-- 
//   beforeRouteEnter(to, from, next) {
  //   const lang = localStorage.getItem("language") || "en";
  //   const currentLang = to.params.lang || lang;
  
  //   if (to.params.lang !== currentLang) {
  //     next(`/${currentLang}/promotion/${to.params.promotionId || ""}`);
  //   } else {
  //     next();
  //   }
  // }, -->
