<template>
  <section>
    <base-info-page>
      <template #title>{{ page.name }}</template>
      <template #default>
        <div class="container-help">
          <div class="main">
            <div class="body" v-html="page.content"></div>
          </div>
        </div>
      </template>
    </base-info-page>
  </section>
</template>
<style  >
.container-help .main ul {
  padding-left: 16px;
}
.container-help .main ul li .maincollapse {
  color: #5a5a5a;
  text-decoration: none;
}
.container-help .sub {
  padding-left: 16px;
  margin-top: 10px;
}
.container-help .main ul li {
  margin-bottom: 10px;
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HelpAndSupportPage",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("skinSettings", ["language", "page"]),
    pathWithLang() {
      return `/${this.language}/help`;
    },
  },
  created() {
    this.$store
      .dispatch("skinSettings/callPage", this.$route.name + "-" + this.language)
      .then(() => {
        document.title = this.page.seo.title;
        const description = this.page.seo.description;
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", description);
        const keywords = this.page.seo.keywords;
        const metaKeywords = document.createElement("meta");
        metaKeywords.setAttribute("name", "keywords");
        metaKeywords.setAttribute("content", keywords);
        document.querySelector("head").appendChild(metaKeywords);

        let anchor = window.top.location.hash.substring(1);
        if(anchor)
        {
          let el = document.querySelector('.subcollapse[data-key="'+anchor+'"]');
           
          if(el)
          {
            let father = el.closest('.collapse');
            if(father)
            {
              father.setAttribute("aria-expanded",true);
              father.classList.add("show")
            }
            el.click();
          }
          else
          {
            let main = document.querySelector('.maincollapse[data-key="'+anchor+'"]');
            if(main)
            {
              main.click();
            }
          }   
        }
           
      })
      .catch((error) => {
        console.error(error);
      });
  },
  mounted() {
  
  },
};
</script>