import { createApp } from 'vue';

import App from './App.vue';
import store from './store/store.js';
import config from '../vue.config.js';
import router from './router';

import 'bootstrap';
// GLOBAL COMPONENTS
import BaseInfoPage from '@/components/global/ui/BaseInfoPage.vue';
import BaseButton from '@/components/global/ui/BaseButton.vue';
import BaseDashboardSection from '@/components/global/ui/BaseDashboardSection.vue';
import BaseLoading from '@/components/global/ui/BaseLoading.vue';
import CardTransactionMobile from '@/components/global/ui/CardTransactionMobile.vue';
import CardTransactionDesktop from '@/components/global/ui/CardTransactionDesktop.vue';
import i18n from '@/components/translations/translations.js';

const app = createApp(App);

app.component('base-info-page', BaseInfoPage);
app.component('base-button', BaseButton);
app.component('base-dashboard-section', BaseDashboardSection);
app.component('base-loading', BaseLoading);
app.component('card-transaction-mobile', CardTransactionMobile);
app.component('card-transaction-desktop', CardTransactionDesktop);

app.use(i18n);
app.use(store);
app.use(config);
app.use(router);

router.isReady().then(() => {
	app.mount('#app');
});

router.beforeEach(function (to, _, next) {
	const playerLogged = localStorage.getItem('X19fYWNjZXNzX19f') != null;
	let pageAccess = to.meta.restrictedPage;

	switch (pageAccess) {
		case true:
			if (playerLogged) {
				next();
			} else {
				const language = to.params.lang || 'en';
				const pathCercato = to.path.split(`/${language}/`)[1];
				const modifiedPath = `/${language}/dashboard/${pathCercato}`;
				to.path = modifiedPath;
				if (to.meta.restrictedPage) {
					//salvo nel sessionStorage 
					sessionStorage.removeItem('temp-route')
					sessionStorage.setItem('savedPath', to.fullPath);
					window.location.href = `/${language}/login`;
					break;
				}
			}
			break;
		case false:
			if (playerLogged) {
				next({ name: 'casino' });
			} else {
				next();
			}
			break;
		default:
			next();
	}
});

