<template>
  <div>
    <div class="container-fluid back-main-header"></div>
    <div class="container-fluid main-header">
      <div class="row align-items-center row-header">
        <div class="col-2">
          <div>
            <transition name="fade">
              <div
                id="back-blur-left"
                :class="isLeftMenuOpen ? 'open-menu' : ''"
                @click="openLeftMenu"
                v-if="isLeftMenuOpen"
              ></div>
            </transition>
            <div id="hamburger-menu-left" :class="isLeftMenuOpen ? 'adding-margin' : ''">
              <div
                class="menu-left-button"
                :class="isLeftMenuOpen ? 'open-menu' : ''"
                @click="openLeftMenu"
              ></div>
              <i
                class="bi bi-list"
                :class="isLeftMenuOpen ? 'open-menu' : ''"
                @click="openLeftMenu"
              ></i>
              <div id="menu-left" class="main-menu-container">
                <div class="container menu-link-container">
                  <i
                    class="bi bi-x-circle-fill close-button-left show"
                    @click="openLeftMenu"
                  ></i>
                  <div>
                    <div class="row">
                      <div class="col-12 my-2">
                        <router-link
                          :to="{
                            name: 'kajotPrimePage',
                            params: { lang: this.languages },
                          }"
                          @click="openLeftMenu"
                        >
                          <div class="menu-link">
                            <i class="bi bi-dice-1-fill"></i>
                            {{ translations.header.kajotPrime ?? "Kajot Prime" }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2">
                        <router-link
                          :to="{ name: 'CasinoPage', params: { lang: this.languages } }"
                          @click="openLeftMenu"
                        >
                          <div class="menu-link">
                            <i class="bi bi-dice-1-fill"></i>
                            {{ translations.header.casino }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2">
                        <router-link
                          :to="{ name: 'live-casino', params: { lang: this.languages } }"
                          @click="openLeftMenu"
                        >
                          <div class="menu-link">
                            <i class="bi bi-dice-2-fill"></i>
                            {{ translations.header.live_casino }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2">
                        <router-link
                          :to="{ name: 'crash-games', params: { lang: this.languages } }"
                          @click="openLeftMenu"
                        >
                          <div class="menu-link">
                            <i class="bi bi-dice-1-fill"></i>
                            {{ translations.header.crash_games }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2">
                        <router-link
                          :to="{ name: 'others', params: { lang: this.languages } }"
                          @click="openLeftMenu"
                        >
                          <div class="menu-link">
                            <i class="bi bi-dice-3-fill"></i>
                            {{ translations.header.others }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-2">
                        <router-link
                          :to="{ name: 'promotion', params: { lang: this.languages } }"
                          @click="openLeftMenu"
                        >
                          <div class="menu-link">
                            <i class="bi bi-gift-fill"></i>
                            {{ translations.promotion.promotions }}
                          </div>
                        </router-link>
                      </div>
                    </div>
                    <div class="imgIntoMobileMenu">
                      <img src="@/assets/img/logoprimeshadow.png" alt="Kajot Logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="logo-img">
            <router-link to="/">
              <img src="@/assets/img/logoprimeshadow.png" alt="Kajot Logo" />
            </router-link>
          </div>
        </div>
        <div class="col-2">
          <div>
            <transition name="fade">
              <div
                id="back-blur-right"
                :class="isRightMenuOpen ? 'open-menu' : ''"
                @click="openRightMenu"
                v-if="isRightMenuOpen"
              ></div>
            </transition>
            <div
              id="hamburger-menu-right"
              :class="isRightMenuOpen ? 'adding-margin' : ''"
            >
              <!------------------------------------->
              <div
                v-if="isAuthenticated"
                class="menu-right-button"
                :class="isRightMenuOpen ? 'open-menu' : ''"
                @click="openRightMenu"
              ></div>

              <div
                v-else
                class="menu-right-button"
                :class="isRightMenuOpen ? 'open-menu' : ''"
                @click="redirectToLogin"
              ></div>
              <!-------------------------------------->

              <i
                class="bi bi-person-fill"
                :class="isRightMenuOpen ? 'open-menu' : ''"
              ></i>

              <div id="menu-right" class="main-menu-container">
                <div class="container menu-link-container">
                  <i
                    class="bi bi-x-circle-fill close-button-right show"
                    @click="openRightMenu"
                  ></i>

                  <div class="row" v-if="isAuthenticated">
                    <div class="col-12 my-2">
                      <div class="welcome-player">
                        {{ translations.registration.Welcome }},
                      </div>
                      <div class="welcome-name">{{ playerInfo.name }}</div>
                    </div>
                    <div class="col-12 my-2">
                      <router-link
                        :to="`/${this.languages}/deposit`"
                        @click="openRightMenu"
                      >
                        <div class="welcome-balance">
                          {{ translations.account_balance.Balance }}:
                          {{ playerInfo?.currency?.symbol ?? "" }}
                          {{
                            (
                              playerInfo?.balance?.total + playerInfo?.balance?.bonus
                            ).toFixed(2)
                          }}
                        </div>
                      </router-link>
                    </div>
                    <div class="col-12 my-2">
                      <router-link :to="{ name: 'informations' }" @click="openRightMenu">
                        <div class="menu-link">
                          <i class="bi bi-person-fill"></i>
                          {{ translations.buttons.dashboard }}
                        </div>
                      </router-link>
                    </div>
                  </div>
                  <div class="row" v-if="isAuthenticated">
                    <div class="col-12 my-2">
                      <router-link
                        :to="`/${this.languages}/deposit`"
                        @click="openRightMenu"
                      >
                        <div class="menu-link">
                          <i class="bi bi-cash-coin"></i>
                          {{ translations.buttons.deposit_now }}
                        </div>
                      </router-link>
                    </div>
                    <div class="col-12 my-2">
                      <router-link :to="{ name: 'withdraw' }" @click="openRightMenu">
                        <div class="menu-link">
                          <i class="bi bi-cash"></i>
                          {{ translations.buttons.Withdraw }}
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <div class="row d-flex mt-3" v-if="!isAuthenticated">
                    <div class="col-12">
                      <router-link :to="{ name: 'login' }" @click="openRightMenu">
                        <base-button
                          color="orange"
                          :caption="translations.login.login"
                          id="login"
                          nomargin="true"
                        ></base-button>
                      </router-link>
                    </div>
                  </div>

                  <div class="row d-flex my-3" v-if="!isAuthenticated">
                    <div class="col-12">
                      <router-link
                        :to="`/${this.languages}/registration`"
                        @click="openRightMenu"
                      >
                        <base-button
                          color="grey"
                          :caption="translations.login.sign_up"
                          id="signup"
                          nomargin="true"
                        ></base-button>
                      </router-link>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 my-2">
                      <router-link :to="{ name: 'help' }" @click="openRightMenu">
                        <div class="menu-link">
                          <i class="bi bi-question-circle-fill"></i>
                          {{ translations.header.help }}
                        </div>
                      </router-link>
                    </div>
                  </div>

                  <div class="row d-flex mt-3" v-if="isAuthenticated">
                    <div class="col-12">
                      <base-button
                        @click="logoutPlayer"
                        color="grey"
                        :caption="translations.buttons.LogOut"
                        id="logout"
                        nomargin="true"
                      ></base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-wrapper w-100 d-flex justify-content-center align-items-center">
        <button
          class="link-button-mobile"
          @click="redirectToPrimePage"
          :class="{ routeSelected: $route.name === 'kajotPrimePage' }"
        >
          Kajot
        </button>

        <button class="link-button-mobile" @click="openLeftMenu">Prime</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRightMenuOpen: false,
      isLeftMenuOpen: false,
      languages: localStorage.getItem("language") ?? "en",
    };
  },
  computed: {
    // isMenuOpenOrOnTop() {
    // 	return this.isTop * !this.isRightMenuOpen;
    // },
    gamePageHeader() {
      return this.isGamePage ? "game-page-header" : "";
    },
  },
  props: ["translations", "isAuthenticated", "playerInfo", "isTop", "isGamePage"],
  methods: {
    openLeftMenu() {
      this.isLeftMenuOpen = !this.isLeftMenuOpen;
      if (this.isLeftMenuOpen) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
      this.isRightMenuOpen = false;
    },
    openRightMenu() {
      this.isRightMenuOpen = !this.isRightMenuOpen;
      if (this.isRightMenuOpen) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
      this.isLeftMenuOpen = false;
    },
    logoutPlayer() {
      this.openRightMenu();
      this.$store.dispatch("player/logout");
    },
    redirectToLogin() {
      this.$router.push({ name: "login" });
    },
    redirectToPrimePage() {
      this.$router.push({ name: "kajotPrimePage" });
    },
  },
};
</script>

<style scoped>
.back-main-header,
.main-header {
  min-height: 3rem;
  background-color: var(--darker-grey);
  transition: var(--short-trans);
}

.back-main-header.game-page-header,
.main-header.game-page-header {
  background-color: transparent;
  transition: var(--short-trans);
}

.main-header {
  position: fixed;
  top: 0;
  background-color: var(--darker-grey);
  box-shadow: var(--light-shadow);
  transition: var(--short-trans);
  z-index: 89;
}

.row-header {
  min-height: 3rem;
}

.logo-img {
  text-align: center;
  transition: var(--short-trans);
}

.logo-img img {
  max-height: 2.5rem;
}

#back-blur-right,
#back-blur-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 250vw;
  height: 250vh;
  filter: blur(0);
  transition: var(--short-trans);
}

#back-blur-right.open-menu,
#back-blur-left.open-menu {
  background: rgba(155, 155, 155, 0.8);
  filter: blur(50px);
  transition: var(--short-trans);
  z-index: 99;
}

#hamburger-menu-left,
#hamburger-menu-right {
  display: block;
  position: relative;
  margin: 0.5rem auto;
  z-index: 199;
  -webkit-user-select: none;
  user-select: none;
  transition: var(--short-trans);
}

#hamburger-menu-left.adding-margin,
#hamburger-menu-right.adding-margin {
  /* margin-top: 30px; */
  transition: var(--short-trans);
}

#hamburger-menu-left .menu-left-button,
#hamburger-menu-right .menu-right-button {
  display: block;
  top: -2rem;
  left: -2rem;
  width: 10rem;
  height: 3rem;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 199;
  -webkit-touch-callout: none;
}

/* .fake-container {
		display: flex;
		top: -2rem;
		left: -2rem;
		width: 10rem;
		height: 3rem;
		position: absolute;
		cursor: pointer;
		opacity: 1;
		-webkit-touch-callout: none;
		justify-content: end;
	} */
#hamburger-menu-left span,
#hamburger-menu-right span {
  position: relative;
  display: block;
  width: 33px;
  height: 4px;
  border-radius: 3px;
  margin-bottom: 5px;
  background: var(--light-grey);
  z-index: 159;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#hamburger-menu span.game-page-header {
  background: var(--dirty-white);
  transition: var(--short-trans);
}

#hamburger-menu-left span.game-page-header,
#hamburger-menu-right span.game-page-header {
  background: var(--dirty-white);
  transition: var(--short-trans);
}

#hamburger-menu-left .menu-left-button.open-menu ~ span,
#hamburger-menu-right .menu-right-button.open-menu ~ span {
  /* opacity: 1; */
  opacity: 0;
  /* transform: rotate(45deg) translate(-2px, -1px);
		background: var(--dark-orange); */
}

#hamburger-menu-left .menu-left-button.open-menu ~ span:nth-last-child(3),
#hamburger-menu-right .menu-right-button.open-menu ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/* #hamburger-menu-left .menu-left-button.open-menu ~ span:nth-last-child(2),
	#hamburger-menu-right .menu-right-button.open-menu ~ span:nth-last-child(2) {
		transform: rotate(-45deg) translate(0, -1px);
	} */
#hamburger-menu-right .menu-right-button.open-menu ~ .main-menu-container {
  transform: translateX(-14rem);
}

#hamburger-menu-left .menu-left-button.open-menu ~ .main-menu-container {
  transform: translateX(0);
}

#hamburger-menu-left span:first-child,
#hamburger-menu-right span:first-child {
  transform-origin: 0% 0%;
}

#hamburger-menu-left span:nth-last-child(2),
#hamburger-menu-right span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#hamburger-menu-left a,
#hamburger-menu-right a {
  text-decoration: none;
}

.menu-link-container {
  height: 85vh;
  overflow-y: auto;
}

#menu-right {
  position: absolute;
  width: 30rem;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 10rem;
  padding-right: 12rem;
  border-radius: 10px;
  overflow-y: hidden;
  background: rgb(33, 37, 41);
  /* background: #ededed; */
  list-style-type: none;
  /* -webkit-font-smoothing: antialiased; */
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu-left {
  position: absolute;
  width: 23rem;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 10rem;
  padding-left: 4rem;
  border-radius: 10px;
  overflow-y: hidden;
  /* background: #ededed; */
  background: rgb(33, 37, 41);
  list-style-type: none;
  /* -webkit-font-smoothing: antialiased; */
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

.welcome-player {
  font-size: 0.7rem;
  color: var(--dark-orange);
}

.welcome-name {
  font-size: 1rem;
  font-weight: 700;
}

.welcome-balance {
  font-size: 1.3rem;
  color: white;
  background: var(--light-orange);
  padding: 5px 10px;
  text-align: center;
  border-radius: 10px;
  box-shadow: var(--light-shadow);
}

i:hover {
  cursor: pointer;
}

i:not(.close-button-left):not(.close-button-right) {
  margin-right: 1rem;
  text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc;
}

#hamburger-menu-left > i {
  opacity: 1;
  text-shadow: none;
  margin-right: 0;
  position: absolute;
  top: -1.7rem;
  font-size: 2rem;
  color: var(--light-grey);
  transition: var(--short-trans);
}

#hamburger-menu-left > i.open-menu {
  opacity: 0;
  transition: var(--short-trans);
}

#hamburger-menu-right > i {
  opacity: 1;
  text-shadow: none;
  margin-right: 0;
  position: absolute;
  top: -1.7rem;
  font-size: 2rem;
  color: var(--light-grey);
  transition: var(--short-trans);
}

#hamburger-menu-right > i.open-menu {
  opacity: 0;
  transition: var(--short-trans);
}

#menu-left.menu-left-open {
  width: 100%;
}

.close-button-left.show {
  position: absolute;
  top: 5rem;
  left: 3.4rem;
  color: var(--dark-orange);
  transition: var(--short-trans);
  font-size: 2rem;
  z-index: 200;
}

.close-button-right.show {
  position: absolute;
  top: 5rem;
  right: 11rem;
  color: var(--dark-orange);
  transition: var(--short-trans);
  font-size: 2rem;
  z-index: 200;
}

#menu-left .menu-link,
#menu-right .menu-link {
  padding: 10px 0;
  font-size: 1.2rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  text-shadow: #ededed;
  /* text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc; */
}

.menu-link:active,
.menu-link:active i,
.router-link-exact-active .menu-link,
.router-link-exact-active .menu-link > i {
  color: var(--dark-orange);
}

.imgIntoMobileMenu {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 10px;
}

.button-wrapper {
  padding: 10px 0px;
}

.link-button-mobile {
  background: var(--light-grey);
  transition: var(--short-trans);
  color: white;
  padding: 5px 20px;
  flex: 1;
  width: 100%;
  text-transform: uppercase;
  border-radius: 5px;
  outline: none;
  border: none !important;
  margin: 0px 5px;
  font-weight: 600;
  text-align: center;
}

.link-button-mobile:hover {
  background: rgb(87, 85, 84);
}

.routeSelected {
  background: var(--dark-orange);
}

.routeSelected:hover {
  background: rgb(209, 73, 9);
}
</style>

<!-- /* mix-blend-mode: multiply; */
/* background-blend-mode: multiply; */
/* filter: drop-shadow(10px 6px 4px black); */ -->
