import mutations from './registrationAndRecovery/mutations.js';
import actions from './registrationAndRecovery/actions.js';
import getters from './registrationAndRecovery/getters.js';
export default {
	namespaced: true,
	state() {
		return {
			path: {
				recoveryPassword: 'auth/password/recovery',
				manyAttempts: 'auth/password/manyAttempts',
				checkUsername: 'auth/checkusername',
				checkEmail: 'auth/checkemail',
				checkMobile: 'auth/checkmobile',
				checkFields: 'auth/checkfields',
				restore: (token) => {
					return `auth/status/${token}`;
				},
				register: 'auth/register',
				kyc: 'kyc/players/',
				static: 'static/players/',
				twilio: 'notifications/twilio/send_sms/verification',
				twilioCheck: 'notifications/twilio/checkMobile',
				sendEmailAgain: (id) => {
					return `notifications/players/${id}/registration`;
				},
				notification: (service) => {
					return `notifications/players/verify_${service}`;
				},
			},
			// REGISTRATION
			registration_nickname: '',
			registration_password: '',
			isNicknameInvalid: true,
			nicknameErrorMessage: null,
			passwordValidated: false,
			isEmailInvalid: true,
			emailErrorMessage: null,
			emailValidated: false,
			isMobileInvalid: true,
			mobileErrorMessage: null,
			isFieldsInvalid: true,
			fieldsErrorMessage: null,
			isPasswordInvalid: true,
			passwordErrorMessage: null,
			passwordMatchControl: false,
			confirmPasswordFieldError: false,
			verificationPlayerID: '',
			verificationConfirmed: false,
			codeSmsCorrect: false,
			codeMailCorrect: false,
			isSMSCalled: false,
			isTwilioOkay: false,
			sendVerificationIsOkay: false,
		};
	},
	mutations,
	actions,
	getters,
};
