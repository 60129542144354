<template>
  <base-dashboard-section>
    <template #nameSection>{{ translations.myprofile.Player_informations }}</template>
    <template #default>
      <div class="container">
        <div class="row d-flex justify-content-center">
          <form @submit.prevent>
            <div class="form-group row mt-4 mb-5">
              <div class="col-12">
                <b>{{ translations.registration.personal_details }}</b>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="email"
                  >{{ translations.myprofile.Name }}:</label
                >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.name"
                  disabled
                />
              </div>
              <!-- <div v-if="0" class="col-12 col-md-6 mt-3">
                <label class="form-label" for="mobile"
                  >{{ translations.myprofile.Mobile_number }}:</label
                >
                <input
                  id="mobile"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.mobile"
                  v-model="playerNumberMobile"
                />
              </div> -->
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="email"
                  >{{ translations.myprofile.Email }}:</label
                >
                <input
                  id="email"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.email"
                  :disabled="playerInfo?.email_verified_at"
                  v-model="playerEmail"
                />

                <div v-if="!playerInfo?.email_verified_at" class="col-12 col-md-12 mt-2">
                  <base-button
                    color="orange"
                    @click="confirmEmail"
                    :disabled="isTimerMail"
                    nomargin="true"
                    :caption="translations.buttons.confirm_email ?? 'Confirm Email'"
                  ></base-button>
                  <small v-if="isTimerMail">
                    {{
                      translations?.myprofile?.you_can_try_new_request ??
                      " You can try with a new request in"
                    }}
                    {{ countdownMail }} {{ translations?.myprofile?.sec ?? "sec" }}
                  </small>
                </div>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="gender"
                  >{{ translations.myprofile.Gender }}:</label
                >
                <input
                  id="gender"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.gender"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="dob">{{
                  translations.myprofile.Date_of_birth
                }}</label>
                <input
                  id="dob"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.dob"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="country"
                  >{{ translations.myprofile.Country_of_Residence }}:</label
                >
                <input
                  id="country"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.country"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="city"
                  >{{ translations.myprofile.City_of_Residence }}:</label
                >
                <input
                  id="city"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.city"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="address_line_1"
                  >{{ translations.myprofile.Address }}:</label
                >
                <input
                  id="address_line_1"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.address_line_1"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="address_line_2"
                  >{{ translations.myprofile.street_number ?? "Street number" }}:</label
                >
                <input
                  id="address_line_2"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.address_line_2"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="postal_code"
                  >{{ translations.myprofile.Postal_Code }}:</label
                >
                <input
                  id="postal_code"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.postal_code"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="nationality">
                  {{ translations.registration.nationality }}:
                </label>
                <input
                  id="nationality"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.information.nationality"
                  disabled
                />
              </div>

              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="mobile">
                  {{ translations.myprofile.Mobile_number }}
                </label>
                <input
                  id="mobile"
                  type="text"
                  class="form-control"
                  :placeholder="
                    playerInfo.mobile && playerInfo.sms_verified_at
                      ? '+' + playerInfo.mobile
                      : translations?.myprofile?.Mobile_number
                  "
                  :disabled="playerInfo.mobile != null && playerInfo?.sms_verified_at"
                  v-model="phoneNumberMobile"
                  @keyup="checkPhoneNumberValidity"
                  @input="replaceInvalidChar(phoneNumberMobile)"
                />
                <small v-if="!playerInfo.mobile && !showError">
                  {{
                    translations?.myprofile?.help_mobile ??
                    "Enter the number with the prefix. Example: +393331234567"
                  }}
                </small>
                <small
                  v-if="isMobileInvalid && showError"
                  class="alert-phone-not-valid"
                  >{{
                    translations?.myprofile?.error_phone_not_valid_or_already_used ??
                    "Invalid or already used phone number"
                  }}</small
                >
              </div>
              <div v-if="!playerInfo.sms_verified_at" class="col-12 col-md-6 mt-3">
                <label class="form-label">&nbsp;</label>
                <base-button
                  color="orange"
                  @click="confirmNumber"
                  nomargin="true"
                  :caption="translations.buttons.Confirm_phone"
                  :disabled="
                    playerInfo.sms_verified_at || !isNumberMobileCorrect || isTimerTwilio
                  "
                ></base-button>
                <small v-if="isTimerTwilio">
                  {{
                    translations?.myprofile?.you_can_try_new_request ??
                    " You can try with a new request in"
                  }}
                  {{ countdown }} {{ translations?.myprofile?.sec ?? "sec" }}
                </small>
              </div>
              <!-- <div v-if="!playerInfo?.email_verified_at" class="col-12 col-md-12 mt-3">
                <label class="form-label">&nbsp;</label>
                <base-button
                  color="orange"
                  @click="confirmEmail"
                  :disabled="isTimerMail"
                  nomargin="true"
                  :caption="translations.buttons.confirm_email ?? 'Confirm Email'"
                ></base-button>
                <small v-if="isTimerMail">
                  {{
                    translations?.myprofile?.you_can_try_new_request ??
                    " You can try with a new request in"
                  }}
                  {{ countdownMail }} {{ translations?.myprofile?.sec ?? "sec" }}
                </small>
              </div> -->
            </div>
            <div class="form-group row mt-4 mb-5">
              <div class="col-12">
                <b>{{ translations.myprofile.Player_data }}</b>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="username"
                  >{{ translations.myprofile.Nickname }}:</label
                >
                <input
                  id="username"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.username"
                  disabled
                />
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="id"
                  >{{ translations.myprofile.Player_id }}:</label
                >
                <input
                  id="id"
                  type="text"
                  class="form-control"
                  :placeholder="playerInfo.user_id"
                  disabled
                />
              </div>
            </div>

            <div class="form-group row mt-4 mb-5">
              <div class="col-12">
                <b>{{ translations.myprofile.settings ?? "Configuration" }}</b>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label" for="reality_minutes">{{
                  translations.myprofile.mga_reality_minutes ?? "Reality Minutes"
                }}</label>
                <select
                  v-model="playerInfo.information.reality_minutes"
                  class="form-control"
                >
                  <option
                    :selected="
                      playerInfo.information.reality_minutes == 15 ? true : false
                    "
                    value="15"
                  >
                    15 {{ translations.myprofile.minutes ?? "minutes" }}
                  </option>
                  <option
                    :selected="
                      playerInfo.information.reality_minutes == 30 ? true : false
                    "
                    value="30"
                  >
                    30 {{ translations.myprofile.minutes ?? "minutes" }}
                  </option>
                  <option
                    :selected="
                      playerInfo.information.reality_minutes == 45 ? true : false
                    "
                    value="45"
                  >
                    45 {{ translations.myprofile.minutes ?? "minutes" }}
                  </option>
                  <option
                    :selected="
                      playerInfo.information.reality_minutes == 60 ? true : false
                    "
                    value="60"
                  >
                    60 {{ translations.myprofile.minutes ?? "minutes" }}
                  </option>
                </select>
                <small>{{
                  translations.myprofile.reality_minutes_help ??
                  "Configure every how many minutes, during the game, a popup appears asking you if you still want to continue playing or stop."
                }}</small>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label class="form-label">&nbsp;</label>
                <base-button
                  @click="updatePlayer"
                  color="orange"
                  nomargin="true"
                  :caption="translations.buttons.Confirm"
                ></base-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </template>
  </base-dashboard-section>
</template>

<script>
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";

export default {
  data() {
    return {
      phoneNumberMobile: "",
      isNumberMobileCorrect: false,
      disabledButton: true,
      isTimerTwilio: false,
      isTimerMail: false,
      countdown: 0,
      countdownMail: 0,
      expiresAt: null,
      showError: false,
      playerEmail: "",
    };
  },
  mounted() {
    const savedExpiresAt = localStorage.getItem("expires_at");
    const savedIsTimerOn = localStorage.getItem("is_timer_on");

    if (savedExpiresAt && savedIsTimerOn === "true") {
      const expiresAt = new Date(savedExpiresAt);
      const now = new Date();

      if (expiresAt > now) {
        this.expiresAt = expiresAt;
        this.isTimerTwilio = true;
        this.updateCountdown();
      } else {
        localStorage.removeItem("expires_at");
        localStorage.removeItem("is_timer_on");
      }
    }
    this.phoneNumberMobile = this.playerInfo?.mobile ? "+" + this.playerInfo?.mobile : "";

    if (this.phoneNumberMobile !== "") {
      this.isNumberMobileCorrect = true;
    }

    this.playerEmail = this.playerInfo?.email;
    // const lang = localStorage.getItem("language") || "en";
    // const titles = {
    //   en: "Kajot Online Casino | My data",
    //   sk: "Kajot Online Casino | Moje údaje",
    //   cs: "Kajot Online Casino | Moje údaje",
    //   de: "Kajot Online Casino | Meine Daten",
    //   hu: "Kajot Online Casino | Adataim",
    // };
    // document.title = titles[lang] || "Kajot Online Casino | My data";
    document.title =
      this.translations?.body?.playerInformationPageTitle ??
      "Kajot Online Casino | My data";
  },
  computed: {
    ...mapGetters("registration", ["isTwilioOkay", "isMobileInvalid", "codeMailCorrect"]),
    ...mapGetters("translations", ["isTranslationLoading", "translations"]),
    ...mapGetters("alert", ["confirmButtonColor", "denyButtonColor"]),
    playerInfo() {
      return this.$store.getters["player/playerInfo"];
    },
  },
  methods: {
    replaceInvalidChar(text) {
      let cleanText = text.replace(/[^\d+]/g, "");

      if (cleanText.charAt(0) !== "+") {
        cleanText = "+" + cleanText;
      }

      if (cleanText.startsWith("+")) {
        cleanText = "+" + cleanText.replace(/\+/g, "");
      }

      cleanText = cleanText.substring(0, 20);

      this.phoneNumberMobile = cleanText;
    },
    startTimer() {
      const now = new Date();
      this.expiresAt = new Date(now.getTime() + 60 * 1000); // 60 seconds in milliseconds
      this.isTimerTwilio = true;

      // Save values in localStorage
      localStorage.setItem("expires_at", this.expiresAt.toISOString());
      localStorage.setItem("is_timer_on", "true");

      // Start the countdown
      this.updateCountdown();
    },
    updateCountdown() {
      this.countdown = Math.max(0, Math.round((this.expiresAt - new Date()) / 1000));

      if (this.countdown > 0) {
        setTimeout(this.updateCountdown, 1000);
      } else {
        // Timer has reached 0, reset values
        this.isTimerTwilio = false;
        localStorage.removeItem("expires_at");
        localStorage.removeItem("is_timer_on");
      }
    },
    checkPhoneNumberValidity() {
      this.disabledButton = true;
      const phoneNumber = this.phoneNumberMobile.replace(/\D/g, "");
      //minimo 8 max 15
      if (phoneNumber.length >= 8 && phoneNumber.length <= 20) {
        this.disabledButton = false;
        this.isNumberMobileCorrect = true;
      } else {
        this.isNumberMobileCorrect = false;
      }
    },
    async confirmNumber() {
      if (this.playerInfo.mobile != null) {
        this.isNumberMobileCorrect = true;
      }
      //se il numero okay procedo a fare la chiamata
      if (this.isNumberMobileCorrect) {
        this.startTimer();
        let playerId = this.playerInfo.user_id;
        let skinId = this.playerInfo.skin_id;
        let body = "";
        if (this.playerInfo.mobile == null) {
          let m = this.phoneNumberMobile.replace(/[^\d\s\w]/g, "");
          body = { mobile: "+" + m };
        } else {
          let m = this.phoneNumberMobile.replace(/[^\d\s\w]/g, "");
          body = {
            mobile: "+" + m,
          };
        }
        const mobile = this.phoneNumberMobile;
        const prefix = "isIntoRegistration";
        this.$store
          .dispatch("registration/checkPhoneNumber", {
            mobile,
            prefix,
          })
          .then(() => {
            if (!this.isMobileInvalid) {
              this.$store
                .dispatch("registration/updatePlayer", { body, playerId })
                .then(() => {
                  this.$store
                    .dispatch("registration/callTwilio", { playerId, skinId })
                    .then(() => {
                      if (this.isTwilioOkay) {
                        this.$store.dispatch("alert/verifyPhone", playerId, {
                          root: true,
                        });
                      } else {
                        return;
                      }
                    });
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              this.showError = true;
              setTimeout(() => {
                this.showError = false;
              }, 5000);
              return;
            }
          });
      }
    },
    startTimerMail() {
      const now = new Date();
      this.expiresAt = new Date(now.getTime() + 60 * 1000); // 60 seconds in milliseconds
      this.isTimerMail = true;

      // Save values in localStorage
      localStorage.setItem("expires_at", this.expiresAt.toISOString());
      localStorage.setItem("is_timer_on", "true");

      // Start the countdown
      this.updateCountdownMail();
    },
    updateCountdownMail() {
      this.countdownMail = Math.max(0, Math.round((this.expiresAt - new Date()) / 1000));

      if (this.countdownMail > 0) {
        setTimeout(this.updateCountdownMail, 1000);
      } else {
        // Timer has reached 0, reset values
        this.isTimerMail = false;
        localStorage.removeItem("expires_at");
        localStorage.removeItem("is_timer_on");
      }
    },
    async confirmEmail() {
      this.startTimerMail();
      let playerId = this.playerInfo.user_id;
      // alert(this.playerEmail);
      let body = {
        email: this.playerEmail,
      };

      try {
        await this.$store.dispatch("registration/updatePlayer", { body, playerId });
        await this.$store.dispatch("registration/sendVerificationCodeAgain", playerId);
        await this.$store.dispatch("alert/verifyMail", playerId, { root: true });
      } catch (error) {
        console.error(error);
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      }
    },
    updatePlayer() {
      let playerId = this.playerInfo.user_id;
      let body = {
        reality_minutes: this.playerInfo.information.reality_minutes,
      };
      this.$store
        .dispatch("registration/updatePlayer", {
          body,
          playerId,
        })
        .then(() => {
          VueCookies.remove("mgaCounter");
          location.reload();
        });
    },
  },
};
</script>

<style scoped>
input:disabled:hover {
  cursor: not-allowed;
}

.alert-phone-not-valid {
  color: var(--dark-orange);
  font-weight: 600;
}
</style>
