<template>
	<div class="accordion accordion-flush" id="accordionFlushBox">
		<slot></slot>
	</div>
</template>

<style scoped>
	#accordionFlushBox {
		border-radius: 10px;
	}
</style>
