<template>
	<div>
		<section v-if="!isLoading" class="d-flex justify-content-center align-items-center">
			<div v-if="optOutToken" class="row justify-content-center w-75">
				<div v-if=" !isCheckingOptOutToken" class="col-12 col-lg-6">
					<form v-if="!requestSent" @submit.prevent class="m-3">
						<h3 class="mb-0">{{ translations.dashboard_menu.unsubscribe }}</h3>
						<p>{{ translations.dashboard_menu.your_email_address }}: <b>{{ playerInfo.email }}</b></p>
						<p class="text-sm mb-0">{{ translations.dashboard_menu.any_more_emails }}</p>
						<div class="mb-3 form-floating">
							<textarea v-model="reason" required autofocus maxlength="255" class="form-control" id="reason"></textarea>
							<label class="reason" :class="{'opacity-0': reason.length > 0}" for="reason">{{ translations.dashboard_menu.reason }}
							</label>
							<small :class="{ 'text-danger': max_reason_characters - reason.length == 0}">{{ max_reason_characters - reason.length }} {{ translations.dashboard_menu.characters_left }}</small>
						</div>
						<input type="hidden" v-model="token" id="token"  />
				 
						<div class="form-check mb-2">
							<input v-model="terms_and_conditions" type="checkbox" class="form-check-input" id="terms_and_conditions">
							<label class="form-check-label" for="terms_and_conditions">{{ translations.dashboard_menu.terms_and_conditions }}</label>
						</div>
						<div class="form-check mb-2">
							<input v-model="newsletter"  type="checkbox"  class="form-check-input" id="newsletter">
							<label class="form-check-label" for="newsletter">Newsletter</label> 
						</div>
			
						<button @click="showAlert()" class="border-0 rounded-1 py-2 text-center text-white w-100 btn-orange">{{ translations.dashboard_menu.send_request }}</button>
						<small class="d-block text-secondary">{{ translations.dashboard_menu.help_text }}</small>
					</form>
					<div v-else :class="{'text-center text-orange fs-5': newsletterOptOut.includes('successfully'), 'text-center text-error fs-5': !newsletterOptOut.includes('successfully')}">
						{{ newsletterOptOutTranslated }}
						<button type="button" @click="redirect" class="border-0 mt-3 btn btn-sm rounded-1 py-2 text-center text-white btn-orange">{{ translations.dashboard_menu.go_back_home }}</button>
					</div>
				</div>
			</div>
			<div v-if="!optOutToken" class="text-center text-error">
				{{ translations.dashboard_menu.page_unavailable }}
				<button type="button" @click="redirect" class="border-0 mt-3 btn btn-sm rounded-1 py-2 text-center text-white w-100 btn-orange">{{ translations.dashboard_menu.go_back_home }}</button>
			</div>
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import Swal from 'sweetalert2';
	export default {
		data() {
			return {
				reason: '',
				terms_and_conditions: true,
				newsletter: true,
				max_reason_characters: 255,
				token: null,
				isLoading: false,
				requestSent: false,
				is_accepted: true,
				newsletterOptOutTranslated: null
			}
		},
		computed: {
			playerInfo() {
				return this.$store.getters['player/playerInfo'];
			},
			translations() {
				return this.$store.getters['translations/translations'];
			},
			...mapGetters('player', ['optOutToken', 'newsletterOptOut']),
			...mapState('player', ['isCheckingOptOutToken']),
		},
		methods: {
			showAlert() {
			 
				if(this.reason.length > 0 && this.token)
				{

					Swal.fire({
						text: this.translations.dashboard_menu.are_you_sure ?? 'Are you sure you want to unsubscribe from our newsletter?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: this.translations.dashboard_menu.YesWant ?? 'Yes, I Want',
						cancelButtonText: this.translations.dashboard_menu.NoThink ?? 'No, I need to think about it',
						confirmButtonColor: '#DB5112',
						title:this.translations.dashboard_menu.Attention ?? 'Attention',
					}).then((result) => {
						let body = {
							terms_and_conditions: this.terms_and_conditions,
							newsletter: this.newsletter,
							token:this.token,
							reason: this.reason,
							is_accepted: this.is_accepted // ---> true --> if you wish for the request to be accepted automatically
						};
						
						if(result.isConfirmed)
							return this.$store.dispatch('player/newsletterOptOut', body);
						}).then(() => {
							this.requestSent = true;
							let lang = localStorage.getItem('language') || 'en';
							
							switch (this.newsletterOptOut) {
								case "You're already unsubscribed from our newsletter":
									switch (lang) {
										case 'sk':
											this.newsletterOptOutTranslated = "Už ste sa odhlásili z odberu našich noviniek";
											break;
										case 'cs':
											this.newsletterOptOutTranslated = "Již jste se odhlásili z odběru našeho newsletteru";
											break;
										case 'de':
											this.newsletterOptOutTranslated = "Sie sind bereits von unserem Newsletter abgemeldet";
											break;
										case 'hu':
											this.newsletterOptOutTranslated = "Már leiratkozott hírlevelünkről";
											break;
										case 'pl':
											this.newsletterOptOutTranslated = "Wypisałeś się już z naszego newslettera";
											break;
										default:
											this.newsletterOptOutTranslated = this.newsletterOptOut
											break;
									}
									break;
								case 'Newsletter opt-out request sent successfully':
									switch (lang) {
										case 'sk':
											this.newsletterOptOutTranslated = "Úspešne odoslaná žiadosť o odhlásenie z odberu noviniek";
											break;
										case 'cs':
											this.newsletterOptOutTranslated = "Žádost o odhlášení z odběru novinek byla úspěšně odeslána";
											break;
										case 'de':
											this.newsletterOptOutTranslated = "Newsletter-Abmeldungsanfrage erfolgreich gesendet";
											break;
										case 'hu':
											this.newsletterOptOutTranslated = "A hírlevél leiratkozási kérelmét sikeresen elküldtük";
											break;
										case 'pl':
											this.newsletterOptOutTranslated = "Żądanie rezygnacji z biuletynu zostało pomyślnie wysłane";
											break;
										default:
											this.newsletterOptOutTranslated = this.newsletterOptOut
											break;
									}
									break;
								case 'Failed to send the request':
									switch (lang) {
										case 'sk':
											this.newsletterOptOutTranslated = "Nepodarilo sa odoslať žiadosť";
											break;
										case 'cs':
											this.newsletterOptOutTranslated = "Požadavek se nepodařilo odeslat";
											break;
										case 'de':
											this.newsletterOptOutTranslated = "Die Anfrage konnte nicht gesendet werden";
											break;
									    case 'hu':
											this.newsletterOptOutTranslated = "Nem sikerült elküldeni a kérést";
											break;
										case 'pl':
											this.newsletterOptOutTranslated = "Nie udało się wysłać żądania";
											break;
										default:
											this.newsletterOptOutTranslated = this.newsletterOptOut
											break;
									}
									break;
							}
							
						});
				} 
			},
			async checkToken() {
				if(this.$route.query.token)
				{
					this.isLoading = true;
					this.token = this.$route.query.token;
					let body = {
						token: this.token
					};
					await this.$store.dispatch('player/checkOptOutToken', body);
					this.isLoading = false;
				}
			},
			redirect() {
				window.location.replace("/");
			}
		},
		created() {
			this.checkToken();
		},
	};
</script>

<style scoped>
	section {
		min-height: 50vh;
	}
	.btn-orange {
		border-radius: 5px;
		cursor: pointer;
		display: block;
		border:none;
		font-weight: 400;
		outline: 0;
		padding: 16px 24px;
		text-align: center;
		text-decoration: none;
		-webkit-transition: background-color .2s ease,-webkit-box-shadow .2s ease;
		-o-transition: background-color .2s ease,box-shadow .2s ease;
		transition: background-color .2s ease,box-shadow .2s ease;
		transition: background-color .2s ease,box-shadow .2s ease,-webkit-box-shadow .2s ease;
		background-color: rgb(219,81,18);
		font-weight: bolder;
		color: white;
		margin: 0px auto; 
	}
	.reason {
		transition: var(--short-trans);
	}
	.text-orange {
		color: var(--dark-orange);
	}
</style>
