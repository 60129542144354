
module.exports = {
	transpileDependencies: true,
	outputDir: process.env.VUE_OUTPUT_DIR,
	devServer: {
		// proxy: 'https://stage-api.theearplatform.com/',
		proxy: process.env.VUE_APP_PROXY,
		webSocketServer: false
	},
	css: {
		loaderOptions: {
			scss: {
				prependData: `
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
          @import "@/scss/functions.scss";
        `,
			},
		},
	},
};
