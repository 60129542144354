<template>
	<section>
		<div class="container mt-5">
			<div class="row d-flex justify-content-center">
				<div class="col-12 col-lg-3 d-flex justify-content-center">
					<div class="point point-1"></div>
					<div class="point point-2"></div>
					<div class="point point-3"></div>
				</div>
			</div>
		</div>
		<transition name="fade">
			<div v-if="isTooSlow">
				<div class="container mt-5">
					<div class="row justify-content-center">
						<div class="col-10">
							<div class="text-center text-error">Loading... <br /></div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</section>
</template>

<script>
	export default {
		data() {
			return {
				counter: 0,
			};
		},
		computed: {
			isTooSlow() {
				if (this.counter > 12) {
					return true;
				} else {
					return false;
				}
			},
		},
		methods: {
			setCounter(value) {
				const counter = () => {
					this.counter++;
				};
				const interval = setInterval(counter, 1000);
				if (value == 'stop') {
					clearInterval(interval);
				}
			},
		},
		mounted() {
			this.setCounter('start');
		},
		beforeUnmount() {
			this.setCounter('stop');
		},
	};
</script>

<style scoped>
	.point {
		width: 1rem;
		height: 1rem;
		margin: 0.7rem;
		position: relative;
		border-radius: 50%;
		-webkit-animation: bouncing 0.6s infinite alternate;
		-moz-animation: bouncing 0.6s infinite alternate;
		-o-animation: bouncing 0.6s infinite alternate;
		animation: bouncing 0.6s infinite alternate;
	}
	.point-2 {
		animation-delay: 200ms;
	}
	.point-3 {
		animation-delay: 400ms;
	}

	@-webkit-keyframes bouncing {
		from {
			border: 3px solid var(--dark-orange);
			background: var(--light-orange);
		}
		to {
			opacity: 0.6;
			transform: translateY(-20px);
			border: 1px solid var(--light-grey);
			background: var(--extralight-grey);
		}
	}

	@-moz-keyframes bouncing {
		from {
			border: 3px solid var(--dark-orange);
			background: var(--light-orange);
		}
		to {
			opacity: 0.6;
			transform: translateY(-20px);
			border: 1px solid var(--light-grey);
			background: var(--extralight-grey);
		}
	}

	@-o-keyframes bouncing {
		from {
			border: 3px solid var(--dark-orange);
			background: var(--light-orange);
		}
		to {
			opacity: 0.6;
			transform: translateY(-20px);
			border: 1px solid var(--light-grey);
			background: var(--extralight-grey);
		}
	}

	@keyframes bouncing {
		from {
			border: 3px solid var(--dark-orange);
			background: var(--light-orange);
		}
		to {
			opacity: 0.6;
			transform: translateY(-20px);
			border: 1px solid var(--light-grey);
			background: var(--extralight-grey);
		}
	}
</style>
