<template>
	<div class="container py-2 my-5">
		<div class="row d-flex justify-content-center">
			<div class="col-12 col-md-11 col-lg-10">
				<div v-if="$slots.nameSection">
					<h1>
						<slot name="nameSection"></slot>
					</h1>
				</div>
				<div class="content mt-4">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
	h1 {
		text-align: center;
		font-size: 30px;
		color: #696969;
		padding-bottom: 5px;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: #b3b3b3;
		width: 100%;
		margin-bottom: 15px;
		line-height: 1.3;
		font-weight: 400;
	}
	.content {
		min-height: 40vh;
	}
</style>
