<template>
	<div>
		<transition name="fade">
			<div v-if="isCheckingToken">
				<base-loading></base-loading>
			</div>
		</transition>
		<transition name="fade">
			<div v-if="!isCheckingToken">
				<div v-if="isTempTokenValid">
					<base-info-page>
						<template #title>{{translations.body.recoverYourPassword ?? 'Recover your Password'}}</template>
						<template #default>
							<div class="container">
								<div class="row d-flex justify-content-center">
									<form @submit.prevent>
										<div class="form-group row mt-4 mb-5">
											<div
												class="row d-flex align-items-center justify-content-center my-2"
											>
												<div class="col-12">
													<label class="form-label" for=""
														>{{ $t('login.newPassword') }}:</label
													>

													<input
														id="new_password"
														type="password"
														class="form-control"
														v-model.trim="new_pwd"
														@keyup="enablingPasswordButton"
													/>
													<transition name="error-message">
														<p v-if="new_pwd_error" class="warning">
															{{ new_pwd_error_message }}
														</p>
													</transition>
												</div>
											</div>
											<div
												class="row d-flex align-items-center justify-content-center my-2"
											>
												<div class="col-12">
													<label class="form-label" for=""
														>{{ $t('login.confirmPassword') }}:</label
													>

													<input
														id="confirm_password"
														type="password"
														class="form-control"
														v-model.trim="confirm_pwd"
														@keyup="enablingPasswordButton"
													/>
													<transition name="error-message">
														<p v-if="confirm_pwd_error" class="warning">
															{{ confirm_pwd_error_message }}
														</p>
													</transition>
												</div>
											</div>
											<div
												class="row d-flex align-items-center justify-content-center my-1"
											>
												<div class="col-12">
													<label class="form-label">&nbsp;</label>
													<base-button
														color="orange"
														:caption="$t('login.changePassword').toUpperCase()"
														:disabled="passwordButtonDisabled"
														@click="changePlayerPassword"
													></base-button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</template>
					</base-info-page>
				</div>
				<div v-else>
					<div class="container mt-5">
						<div class="row d-flex justify-content-center">
							<div class="col-12 col-md-6">
								<div class="text-error text-center">
									{{ $t('login.linkExpired') }}
								</div>

								<base-button
									class="mt-3"
									color="orange"
									:caption="$t('login.goHome').toUpperCase()"
									@click="$router.replace('/')"
								></base-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				email: '',
				new_pwd: null,
				new_pwd_invalid: false,
				new_pwd_error: false,
				new_pwd_error_message: '',
				confirm_pwd: null,
				confirm_pwd_invalid: false,
				confirm_pwd_error: false,
				confirm_pwd_error_message: "The password doesn't match",
				passwordButtonDisabled: true,
			};
		},
		computed: {
			isTempTokenValid() {
				return this.$store.getters['player/isTempTokenValid'];
			},
			isCheckingToken() {
				return this.$store.getters['player/isCheckingToken'];
			},
			translations() {
				return this.$store.getters['translations/translations'];
			},
		},
		watch: {
			confirm_pwd(value) {
				this.confirm_pwd_error = false;
				this.confirm_pwd_invalid = true;
				if (value.length < 5) {
					return false;
				} else {
					if (this.new_pwd != value) {
						this.confirm_pwd_error = true;
					} else {
						this.confirm_pwd_error = false;
						this.confirm_pwd_invalid = false;
					}
				}
			},
			new_pwd(value) {
				this.new_pwd_invalid = true;
				this.new_pwd_error = false;
				this.new_pwd_error_message = '';
				if (value.length == 0) {
					return false;
				} else if (value.length != 0) {
					if (this.validationPassword(value)) {
						this.new_pwd_invalid = false;
						this.new_pwd_error = false;
					} else {
						this.new_pwd_invalid = true;
						this.new_pwd_error = true;
						this.new_pwd_error_message =
							this.translations.registration.password_error;
					}
				}
			},
			email(value) {
				function validationEmail(el) {
					let result = String(el)
						.toLowerCase()
						.match(
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						);
					return result;
				}
				if (validationEmail(value)) {
					this.isEmailInvalid = false;
				} else {
					this.isEmailInvalid = true;
				}
			},
		},
		methods: {
			getTokenFromUrl() {
				const token = this.$route.params.temp_token;
				this.$store.dispatch('player/checkTempToken', token);
			},
			enablingPasswordButton() {
				if (
					this.new_pwd === this.confirm_pwd &&
					!this.new_pwd_invalid &&
					!this.confirm_pwd_invalid &&
					!this.isEmailInvalid
				) {
					this.passwordButtonDisabled = false;
				} else {
					this.passwordButtonDisabled = true;
				}
			},
			changePlayerPassword() {
				if (!this.passwordButtonDisabled) {
					this.$store.dispatch('player/changeForgotPassword', {
						email: this.email,
						new: this.new_pwd,
						confirm: this.confirm_pwd,
					});
				}
			},
			validationPassword(value) {
				let result = String(value).match(
					/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/gm
				);
				return result;
			},
		},
		created() {
			this.getTokenFromUrl();
		},
	};
</script>

<style scoped>
	p.warning {
		margin-top: 0;
	}
</style>
