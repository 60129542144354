import router from '@/router';
export default {
	emailValidation(state, payload) {
		let result = !!String(payload)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		state.emailValidated = !!result;
	},
	passwordValidation(state, payload) {
		let result = String(payload).match(
			/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/gm
		);
		state.passwordValidated = !!result;
	},
	setNicknameStatus(state, { responseData, nicknameAlreadyUsed }) {
		if (responseData.data == 1) {
			state.isNicknameInvalid = false;
		} else if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
			state.nicknameFieldError = true;
			state.nicknameErrorMessage = nicknameAlreadyUsed;
			return false;
		} else {
			console.log(responseData);
		}
	},
	setEmailStatus(state, { responseData, emailAlreadyUsed }) {
		if (responseData.data == 1) {
			state.isEmailInvalid = false;
		} else if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
			state.emailErrorMessage = emailAlreadyUsed;
			return false;
		} else {
			console.log(responseData);
		}
	},
	setPasswordMatchingStatus(state, { password, confirm }) {
		state.confirmPasswordFieldError = false;
		state.passwordMatchControl = false;
		if (confirm.length < 5) {
			return false;
		} else {
			if (password !== confirm) {
				state.confirmPasswordFieldError = true;
			} else {
				state.passwordMatchControl = true;
			}
		}
	},
	setTempUser(state, payload) {
		localStorage.setItem('_usr', payload);
		state.verificationPlayerID = payload;
	},
	sendVerificationCodeAgain(_, payload) {
		console.log(payload);
	},
	deleteTempUser(_, payload) {
		console.log(payload);
	},
	contactVerification(state, { responseData, service }) {
		state.verificationConfirmed = false;
		if (Object.prototype.hasOwnProperty.call(responseData, 'data')) {
			if (responseData.data.status == 'success') {
				state.verificationConfirmed = true;
				if (service == 'sms') {
					state.codeSmsCorrect = true;
					// console.log(responseData.data.message); RISPONDE "SMS CODE SUCCESSFULLY VERIFIED"
					// window.location.href = "/"
				} else {
					state.codeMailCorrect = true;
					// console.log(responseData.data.message); RISPONDE "EMAIL CODE SUCCESSFULLY VERIFIED"
				}
			}
		} else {
			if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
				// alert(responseData.response.data.message);
			} else {
				alert('SERVER ERROR');
			}
		}
	},
	sendKyc(_, payload) {
		if (payload) {
			return;
		}
	},
	checkPhoneNumber(state, { responseData, mobileAlreadyUsed }) {

		if (responseData.data == 1) {
			state.isMobileInvalid = false;
		} else if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
			state.isMobileInvalid = true;
			state.mobileErrorMessage = mobileAlreadyUsed;
			return false;
		} else {
			console.log(responseData);
		}
	},
	setMobileError(state, value) {
		state.mobileErrorMessage = value;
	},
	setPhoneNumberInvalid(state, value) {
		state.isMobileInvalid = value;
		// console.log('**--VALUE AL CLICK--**')
		// console.log(state.isMobileInvalid)
		// console.log('**--VALUE AL CLICK--**')
	},
	checkFields(state, { responseData, fieldsAlreadyUsed }) {

		if (responseData.data == 1) {
			state.isFieldsInvalid = false;
		} else if (Object.prototype.hasOwnProperty.call(responseData, 'response')) {
			state.isFieldsInvalid = true;
			state.fieldsErrorMessage = fieldsAlreadyUsed;
			return false;
		} else {
			console.log(responseData);
		}
	},
	setRestore(state, payload) {

		if (payload.is_valid)
			state.verificationPlayerID = payload.tokenable.user_id;

	},
	saveRegistration(_, payload) {
		console.log(payload);
	},

	redirectToLogin(_, payload) {
		console.log(_);
		alert(payload);
		router.replace({ name: 'login' });
	},
	setIsTwilioOkay(state, value) {
		state.isTwilioOkay = value;
	},
	callTwilio(state, payload) {
		state.isSMSCalled = true;
		if (Object.prototype.hasOwnProperty.call(payload, 'data')) {
			if (payload.data.success == 'success') {
				console.log(payload.data.message);
			}
		} else {
			state.isTwilioOkay = false;
			console.log(payload);
		}
	},
	setSendVerificationIsOkay(state, value) {
		state.sendVerificationIsOkay = value;
	}
};
